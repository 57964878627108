export const lang = {
    "theme": "Тема",
    "logout": "Выход",
    "menu": {
        "dashboards": "Панель",
        "poolproxy": "Пуловый Прокси",
        "totalproxy": "Просмотреть все прокси",
        "addproxy": "Добавить новый прокси",
        "importproxy": "Импорт конфигурации прокси",
        "exportproxy": "Экспорт конфигурации прокси",
        "notproxy": "Прокси для этой валюты не добавлено",
        "setting": "Настройки",
        "setcert": "Установить глобальный TLS/SSL-сертификат",
        "setaccount": "Изменить имя пользователя и пароль",
        "ipban": "Черный список IP",
        "offline": "Оповещение о выходе из сети",
        "webport": "Изменить порт доступа WEB",
        "addwallet": "Добавить ярлык кошелька",
        "addpool": "Добавить ярлык майнинг-пула",
        "uuid": "Просмотреть локальный UUID",
        "restart": "Перезапустить программу",
        "log": "Управление журналом",
        "algo": "Движок алгоритмов",
        "front": "Фронтальный прокси",
        "group": "Управление группой",
        "about": "О нас",
        "version": "Версия программного обеспечения",
        "error_log": "Просмотреть журнал ошибок"
    },
    "sun": "Тёмная тема",
    "moon": "Светлая тема",
    "proxy": {
        "newproxy": "Добавить новый прокси",
        "editproxy": "Редактировать порт",
        "t_deal": "Прокси протокол",
        "t_default": "Основная конфигурация",
        "t_fee": "Конфигурация комиссии",
        "t_conn": "Режим подключения",
        "t_replace": "Заменить указанный кошелек",
        "create_proxy": "Создать прокси",
        "tcp_intro": "Подключение в виде обычного текста, более высокая скорость передачи, но уязвимость к атакам посредника.",
        "ssl_intro": "Шифрование с использованием сертификата, незначительное воздействие на эффективность передачи (по умолчанию используется встроенный сертификат; если необходимо использовать собственный сертификат, перейдите в раздел Настройки - Настройки сертификата для загрузки сертификата).",
        "rms_intro": "Собственный протокол, который позволяет сжимать количество устройств и передачу данных в это программное обеспечение, обеспечивая быструю передачу данных и невозможность атак посредников и атак подделки запросов, но требует клиента фронтального прокси.",
        "port_num": "Номер порта",
        "proxy_coin": "Валюта прокси",
        "max_conn": "Максимальное количество подключений",
        "remark": "Примечание к порту",
        "proxy_addr": "Адрес прокси майнинг-пула",
        "proxy_addr_des": "Введите или выберите адрес целевого майнинг-пула, например btc.f2pool.com:1314",
        "proxy_addr_deal": "Протокол майнинг-пула",
        "conn_test": "Тест подключения",
        "conn_test_ok": "Подключение успешно",
        "conn_test_no": "Подключение не удалось",
        "stand_pool": "Адрес резервного майнинг-пула",
        "stand_pool_des": "Адрес резервного майнинг-пула. После установки, если целевой майнинг-пул столкнется с проблемами, автоматически переключится на резервный майнинг-пул для работы.",
        "stand_pool_deal": "Протокол резервного майнинг-пула",
        "des_remark": "Примечание",
        "new_wallet": "Добавить новый кошелек",
        "allot_wallet": "Выделить кошелек",
        "fee_addr": "Адрес комиссионного майнинг-пула",
        "fee_addr_message": "Если установлено соотношение, это поле обязательно для заполнения.",
        "fee_addr_des": "Извлекать комиссии на этом адресе комиссионного майнинг-пула. Рекомендуется использовать такой же адрес, как и у целевого майнинг-пула.",
        "fee_deal": "Протокол комиссионного майнинг-пула",
        "wallet_addr": "Адрес кошелька или подаккаунта",
        "wallet_perc": "Если установлено соотношение, это поле обязательно для заполнения.",
        "wallet_desc": "Если это двойная добыча, формат конфигурации здесь должен быть адрес кошелька. Адрес кошелька",
        "fee_name": "Наименование комиссии",
        "fee_name_desc": "Наименование устройства для извлечения вычислительной мощности.",
        "fee_percent": "Соотношение комиссии (%)",
        "delete_wallet": "Удалить выделенный кошелек",
        "err_port": "Введите порт для мониторинга в диапазоне от 1 до 65534",
        "err_proxy_coin": "Выберите валюту для прокси",
        "err_pool_addr": "Введите или выберите майнинг-пул",
        "err_pool_mode": "Этот элемент обязателен в режиме майнинг-пула",
        "conn_eff": "Эффективный режим",
        "conn_eff_des": "Полный режим подключения и механизм, практически без потерь извлечения и неотключаемые соединения, автоматическое переключение несовместимых устройств на совместимый режим.",
        "conn_com": "Совместимый режим",
        "conn_com_des": "Устаревшее ядро или модель и некоторые майнинг-пулы с плохой совместимостью могут вызвать избыточное количество недействительных вычислительных мощностей. В этом случае следует выбрать этот режим, который вызовет определенные потери.",
        "conn_pool": "Режим майнинг-пула",
        "conn_pool_des": "Объединяет все соединения с майнинг-пулом, существенно уменьшает пропускную способность направления майнинг-пула, все майнеры будут объединены в этом режиме, а кошельки и имена устройств будут унифицированы, подходит для собственной майнинг-фермы (использовать с осторожностью).",
        "unify_wallet": "Унификация кошелька",
        "unify_wallet_des": "Заменить адрес кошелька на всех устройствах.",
        "unify_name": "Унификация имени устройства",
        "unify_name_des": "Заменить имя на всех устройствах.",
        "replace_err": "Обе опции должны быть установлены или сняты одновременно",
        "ori_wallet": "Исходный кошелек",
        "ori_wallet_des": "Заменить соответствующий адрес кошелька указанным адресом кошелька. Поддерживает несколько адресов кошелька в формате одной строки.",
        "target_wallet": "Заменить на",
        "target_wallet_des": "Заменить адрес кошелька на этот адрес.",
        "create_error": "Создание не удалось",
        "create_config_error": "Проверка конфигурации не прошла. Внесите необходимые изменения в соответствии с предупреждениями.",
        "alert_warning": "Предупреждение⚠️",
        "alert_warning_text": "По умолчанию адрес пула комиссий установлен на тот же, что и целевого пула. Если вы указываете отдельный адрес пула комиссий, это может вызвать проблемы. Если вы действительно хотите указать отдельный адрес пула комиссий, введите «I understand that the different addresses of agent pool and commission pool may cause the equipment to fail to work normally» в поле ввода ниже.",
        "alert_warning_text2": "Должна быть хотя бы одна конфигурация кошелька, даже если нет конфигурации доли.",
        "alert_warning_text3": "Этот протокол не открыт",
        "alert_warning_text4": "Клиент RMS в настоящее время находится в разработке. Пожалуйста, подождите несколько дней."
    },
    router: {
        login: "Логин",
        dashboard: "Панель управления",
        com: "Общая статистика",
        pool_proxy: "Пул Прокси"
    },
    cancel: "Отмена",
    pleaseSelect: "Пожалуйста, выберите",
    alert: {
        set_success: "Настройки успешно сохранены",
        set_lang: "Некоторые языки будут применены после ручного обновления страницы."
    },
    hashrate: "Хэшрейт",
    chart: {
        effect: "Эффективный хэшрейт",
        invalid: "Недействительный хэшрейт",
        pump: "Откачиваемый хэшрейт",
        e_s: "Действительные акции",
        i_s: "Недействительные акции",
        p_s: "Откачанные акции"
    },
    eff_hash: "Эффективный хэшрейт",
    eff_hash_r: "Текущий эффективный хэшрейт",
    fuck_error: "Фатальная ошибка, обратитесь к администратору.",
    nons_title: "Недоступно",
    nons_text: "Эта функция находится в разработке, пожалуйста, подождите несколько дней.",
    attack: "Перехват атаки",
    ping: "Среднее время отклика",
    c_ping: "Текущее среднее время отклика",
    count: "Раз",
    desk_per: "Загрузка майнера",
    desk_title: "Использование диска",
    desk_total: "Общее использование",
    desk_pro: "Использование программы",
    desk_res: "Свободно",
    login: {
        hello: "Добро пожаловать 👋",
        message: "Войдите в свою учетную запись, чтобы начать использовать лучший инструмент для управления фермой.",
        user: "Имя пользователя",
        user_des: "Введите имя пользователя",
        pass: "Пароль",
        pass_des: "Введите пароль",
        verify: "Проверочный код",
        verify_des: "Введите содержимое изображения",
        reme: "Запомнить учетную запись",
        forgot: "Забыли пароль?",
        login: "Войти"
    },
    logout_titme: "Выйти?",
    logout_text: "Вы собираетесь выйти из текущего аккаунта",
    server_run_time: "Время работы программы:",
    notice: "Уведомление:",
    add_proxy: "Добавить новый прокси",
    hash_ratio: "Текущий хэшрейт",
    coin_active_rank: "Активное распределение монет",
    rank: "Ранг",
    coin: "Монета",
    hash: "Хэшрейт",
    active_device: "Активные устройства",
    active_device_r: "Текущее активное",
    die_device: "Неактивные устройства",
    current_die: "Текущие неактивные",
    ratio: "Пропорция",
    get_config_error: "Ошибка получения информации о конфигурации!",
    save_success: "Сохранение успешно~",
    save_error: "Ошибка сохранения!",
    save_error_1: "Ошибка сохранения конфигурации из-за неудачного закрытия порта.",
    create_success: "Создание успешно~",
    create_error: "Ошибка создания!",
    update_config: "Обновить конфигурацию",
    pool_hash: "Хэшрейт пула",
    system_log: "Журнал системы",
    error_hash: "Недействительный хэшрейт",
    error_hash_ratio: "Текущий недействительный хэшрейт/пропорция",
    net_stats: "Статистика сети",
    net_stats_des: "Ресурсы, потребляемые программой вверх/вниз",
    net_up: "Отправка",
    net_down: "Прием",
    active_device_stats: "Статистика активных устройств",
    total_pool: "Все пулы",
    active_device_ratio: "Уровень активности устройств",
    port_deal: "Протокол порта",
    port_des: "Порт/Примечание",
    port_line: "В сети/Не в сети",
    current_hash: "Текущий хэшрейт",
    port_ratio: "Эффективность",
    connect_addr: "Адрес подключения",
    status: "Статус",
    opa: "Действие",
    show_detail: "Подробности",
    switch_port: "Открыть/Закрыть порт",
    edit_port: "Редактировать порт",
    hot_update_wallets: "Горячее обновление комиссий",
    delete_port: "Удалить порт",
    port_status_success: "Состояние порта: нормально",
    port_status_close: "Порт закрыт",
    port_status_queue: "Очередь, пожалуйста, подождите...",
    delete_port_aff: "Вы уверены, что хотите удалить этот порт?",
    delete_port_ok: "Порт успешно удален",
    delete_port_error: "Ошибка удаления порта",
    close_port_ok: "Порт успешно закрыт",
    close_port_error: "Ошибка закрытия порта",
    open_port_ok: "Порт успешно открыт",
    open_port_error: "Ошибка открытия порта",
    copy_conn: "Адрес подключения скопирован",
    real_stats: "Реальная статистика",
    time_15_hash: "Средний хэшрейт за 15 минут:",
    time_4_hash: "Средний хэшрейт за 4 часа:",
    time_24_hash: "Средний хэшрейт за 24 часа:",
    e: "Действительный",
    f: "Недействительный",
    c: "Откачанный",
    real_ping: "Среднее время отклика",
    ready_port: "Готовые порты",
    no_port: "Нет портов",
    port: "Порт",
    click_create: "Нажмите для создания",
    aves_line_success: "Действительный",
    aves_line_pump: "Откачанный",
    aves_min_average: "Минутный средний",
    aves_hour_average: "Часовой средний",
    port_table_deal: "Протокол порта",
    port_table_name: "Примечание порта",
    port_table_line: "В сети/Не в сети",
    port_table_hash: "Текущий хэшрейт",
    port_table_ping: "Среднее время отклика",
    port_table_status: "Статус",
    port_table_batch: "Групповые операции",
    port_table_no_create: "Не создан",
    port_table_port: "Порт",
    port_table_create: "Нажмите здесь для создания",
    port_table_select: "Пожалуйста, выберите",
    port_table_no_port: "В текущей монете нет портов, сначала создайте их.",
    port_table_select_all: "Выбрать все",
    port_table_open_port: "Открыть выбранные порты",
    port_table_stop_port: "Остановить выбранные порты",
    port_table_delete_port: "Удалить выбранные порты",
    port_table_scripe_1: "Групповые операции завершены",
    port_table_scripe_2: "успешно",
    port_table_scripe_3: "не удалось",
    port_table_scripe_4: "Прокси пул:",
    port_table_scripe_5: "Пул комиссий",
    port_table_scripe_6: "Кошелек комиссий",
    port_table_scripe_7: "Комиссии не настроены, честно-честно.",
    port_table_scripe_8: "Настроено",
    port_table_scripe_9: "Кошельков с комиссиями:",
    port_table_scripe_10: "Общий процент",
    port_table_scripe_11: "Успешные доли с отсылкой:",
    port_table_scripe_12: "Включено",
    port_table_scripe_13: "Выключено",
    proxy_model_default_title: "Основные настройки",
    proxy_model_pump_title: "Настройки комиссий",
    proxy_model_replace_title: "Замена указанного кошелька",
    proxy_model_auth_title: "Другие настройки",
    proxy_model_copy_port: "Скопировать порт",
    proxy_model_add_proxy: "Добавить новый прокси",
    proxy_model_edit_proxy: "Редактировать прокси",
    proxy_model_copy_proxy: "Скопировать прокси",
    proxy_model_create_error: "Создание не удалось!! Выбранная монета не поддерживается текущей версией, пожалуйста, обновите программу, если хотите создать.",
    coin_class_title: "Хэшрейт / Устройство",
    home_log_card: "Результаты операций",
    index_hash_stat_hash_stat: "Статистика хэшрейта",
    index_hash_stat_current_hash: "Текущий хэшрейт",
    index_hash_stat_device_count: "Количество устройств",
    index_hash_stat_online_count: "Активные устройства",
    index_hash_stat_die_count: "Неактивные устройства",
    index_hash_stat_ping: "Среднее время отклика",
    index_hash_stat_current_ping: "Текущее среднее время отклика",
    index_hash_stat_legend_ping: "Отклик",
    index_hash_stat_legend_online: "Активные устройства",
    index_hash_stat_legend_die: "Неактивные устройства",
    port_list_card_menu: "Меню операций",
    port_list_card_review: "Просмотр майнеров в порту",
    port_list_card_edit: "Редактировать порт",
    port_list_card_update: "Горячее обновление настроек комиссий (майнеры не выключаются)",
    port_list_card_copy: "Скопировать порт",
    port_list_card_stop: "Остановить порт",
    port_list_card_open: "Открыть порт",
    port_list_card_delete: "Удалить порт",
    port_list_card_alert_close: "Закрыть порт",
    port_list_card_alert_close_msg: "Вы уверены, что хотите закрыть порт? Устройства на этом порту будут отключены.",
    line_chart_1_online: "Активные устройства",
    line_chart_1_die: "Неактивные устройства",
    line_chart_4_total: "Общее количество",
    line_chart_6_ping: "Отклик",
    coin_card_create: "Создать новый прокси",
    coin_card_hide_no_proxy: "Скрыть монеты без прокси",
    coin_card_hide_no_active: "Скрыть неактивные монеты",
    coin_card_no_search: "Не найдено соответствий, сначала создайте прокси",
    port_card_flow: "Поток данных вверх/вниз",
    port_card_hard: "Память / CPU",
    port_card_rt: "Реальное время:",
    port_card_version: "Версия программы:",
    port_card_runtime: "Время работы программы:",
    port_card_system: "Операционная система:",
    port_card_la: "Нагрузка системы (LA):",
    port_card_cpu: "Количество ядер CPU:",
    port_card_desk: "Всего/Использовано дисковое пространство:",
    port_card_mem: "Всего/Использовано памяти:",
    port_card_device: "Общее количество устройств",
    port_card_line: "Активные/Неактивные:",
    port_card_script_1: "Обнаружена новая версия {originVersion}, текущая версия {localVersion}, нажмите для просмотра журнала обновлений.",
    port_card_chart_online: "Активные устройства",
    port_card_chart_offline: "Неактивные устройства",
    port_card_chart_mem: "Память",
    port_card_chart_ob: "Входящий",
    port_card_chart_send: "Исходящий",
    aves_line_select: "Пожалуйста, выберите",
    port_table_port_list: "Список портов",
    header_bar_com: "Общая статистика",
    header_bar_proxy: "Пул Прокси",
    header_bar_all: "Показать все прокси",
    header_bar_create: "Добавить новый прокси",
    header_bar_import: "Импорт конфигурации прокси",
    header_bar_export: "Экспорт конфигурации прокси",
    header_bar_setting: "Настройки",
    header_bar_ip: "Все IP-адреса портов",
    header_bar_ban: "Черный список кошельков",
    header_bar_runlog: "Журнал работы",
    header_bar_updatelog: "Журнал обновлений",
    header_bar_language: "Язык",
    header_bar_theme: "Тема",
    header_bar_default: "Стандартная тема",
    header_bar_classic: "Классическая тема",
    header_bar_logout: "Выйти из системы",
    menu_bar_re_proxy: "Просмотреть все прокси {coin}",
    menu_bar_https: "Настроить HTTPS доступ к веб-консоли",
    menu_bar_kenc: "Настроить кастомный KENC ключ",
    menu_bar_pool: "Добавить быстрый пул",
    menu_bar_wallet: "Добавить быстрый кошелек",
    add_control_add_client: "Добавить клиента",
    add_control_edit_client: "Редактировать клиента",
    add_control_url_title: "Адрес доступа",
    add_control_auth_addr: "Введите адрес другой веб-консоли",
    add_control_desc: "Пожалуйста, укажите адрес доступа к веб-консоли соответствующего клиента, например, {origin}, если используется HTTPS, необходимо указать адрес HTTPS. Убедитесь, что адрес веб-консоли доступен из интернета.",
    add_control_other_api: "Введите другой API управления клиентами",
    add_control_remark: "Примечание",
    add_control_at_remark: "Введите произвольное примечание...",
    add_control_script_1: "Все три настройки обязательны!",
    add_control_script_2: "Добавление успешно",
    add_control_script_3: "Ошибка добавления",
    add_perset_pool_title: "Добавить быстрый пул",
    add_perset_pool_desc: "- Здесь можно настроить быстрый доступ к пулам, чтобы быстро выбирать их в нужных местах.",
    add_perset_pool_select_coin: "Выберите соответствующую монету",
    add_perset_pool_input_addr_label: "Введите примечание пула",
    add_perset_pool_input_addr_label_desc: "Введите примечание к пулу для удобства поиска",
    add_perset_pool_input_addr: "Введите адрес пула",
    add_perset_pool_input_addr_desc: "Введите адрес пула, убедитесь, что нет лишних пробелов и китайских символов",
    add_perset_pool_input_deal: "Выберите протокол пула",
    add_perset_pool_fast_button: "Добавить быстрый пул",
    add_perset_pool_pool_preview: "- Предварительный просмотр быстрых пулов",
    add_perset_pool_custom_pool: "Пользовательский пул",
    add_perset_pool_scripe_1: "Нет пользовательских пулов для {selectCoin}, добавьте их выше",
    add_perset_pool_name: "Примечание",
    add_perset_pool_addr: "Адрес пула",
    add_perset_pool_deal: "Протокол",
    add_perset_pool_opt: "Опции",
    add_perset_pool_delete: "Удалить",
    add_perset_pool_in_pool: "Предустановленные пулы",
    add_perset_pool_in_scripe_1: "Нет предустановленных пулов для {selectCoin}, свяжитесь с администратором для добавления. Предустановленные пулы можно добавить через горячее обновление.",
    add_perset_pool_script_1: "Ошибка получения адреса предустановленного пула.",
    add_perset_pool_script_2: "Пожалуйста, заполните все данные.",
    add_perset_pool_script_3: "Ошибка получения данных",
    add_perset_pool_script_4: "Конфигурация успешно сохранена!",
    add_perset_pool_script_5: "Ошибка получения данных",
    add_perset_pool_script_6: "Простой перенаправления",
    add_perset_wallet_title: "Добавить быстрый кошелек",
    add_perset_wallet_desc: "- Здесь можно настроить быстрый доступ к кошелькам, чтобы быстро выбирать их в нужных местах.",
    add_perset_wallet_select_coin: "Выберите соответствующую монету",
    add_perset_wallet_input_addr_label: "Введите примечание кошелька",
    add_perset_wallet_input_addr_label_desc: "Введите примечание к кошельку для удобства поиска",
    add_perset_wallet_input_addr: "Введите адрес кошелька",
    add_perset_wallet_input_addr_desc: "Введите адрес кошелька, убедитесь, что нет лишних пробелов и китайских символов",
    add_perset_wallet_input_deal: "Выберите протокол кошелька",
    add_perset_wallet_fast_button: "Добавить быстрый кошелек",
    add_perset_wallet_pool_preview: "- Предварительный просмотр быстрых кошельков",
    add_perset_wallet_custom_pool: "Пользовательский кошелек",
    add_perset_wallet_scripe_1: "Нет пользовательских кошельков, добавьте их выше",
    add_perset_wallet_name: "Примечание",
    add_perset_wallet_addr: "Адрес кошелька",
    add_perset_wallet_deal: "Протокол",
    add_perset_wallet_opt: "Опции",
    add_perset_wallet_delete: "Удалить",
    add_perset_wallet_in_pool: "Предустановленные кошельки",
    add_perset_wallet_in_scripe_1: "Нет предустановленных кошельков, свяжитесь с администратором для добавления. Предустановленные кошельки можно добавить через горячее обновление.",
    add_perset_wallet_script_1: "Ошибка получения адреса предустановленного кошелька.",
    add_perset_wallet_script_2: "Пожалуйста, заполните все данные.",
    add_perset_wallet_script_3: "Ошибка получения данных",
    add_perset_wallet_script_4: "Конфигурация успешно сохранена!",
    add_perset_wallet_script_5: "Ошибка получения данных",
    add_perset_wallet_script_6: "Простой перенаправления",
    device_model_hash_stat_title: "Статистика майнинга",
    device_model_warning: "* Обратите внимание: этот устройство не может перераспределять майнинг между пулами, так как майнинговая мощность этого устройства автоматически переключена на пул прокси. Убедитесь в том, что контролируемый пул прокси настроен правильно.",
    device_model_des: "Описание",
    device_model_name: "Название устройства:",
    device_model_wallet: "Адрес кошелька:",
    device_model_acc: "Доля активных шар:",
    device_model_re: "Доля неактивных шар / добровольно отклоненных шар:",
    device_model_hash: "Текущая майнинговая мощность:",
    device_model_15: "Майнинговая мощность за 15 минут:",
    device_model_4: "Майнинговая мощность за 4 часа:",
    device_model_24: "Майнинговая мощность за 24 часа",
    device_model_c_p: "Текущая майнинговая мощность прокси:",
    device_model_15_p: "Майнинговая мощность прокси за 15 минут:",
    device_model_4_p: "Майнинговая мощность прокси за 4 часа:",
    device_model_24_p: "Майнинговая мощность прокси за 24 часа:",
    device_model_local_hash: "Локальная майнинговая мощность:",
    device_model_device: "Модель/Ядро:",
    device_model_diff: "Текущая сложность задачи:",
    device_model_cut: "Число добровольных отключений устройства:",
    device_model_ratio: "Процент комиссии:",
    device_model_alg: "Алгоритм:",
    device_model_ping: "Задержка:",
    device_model_log_time: "Время входа устройства:",
    device_model_last_sub: "Последнее подтверждение:",
    device_model_last_job: "Последнее задание от пула:",
    device_model_erupt: "Количество одновременных задач:",
    device_model_log: "Журнал устройства",
    device_model_info: "Обычные пользователи могут проигнорировать следующее содержание. Обычно некоторые платы, если установлены три адреса, могут иметь несколько рабочих адресов, один из которых будет активным, а остальные будут резервными. Если у вас возникли проблемы с частым отключением платы, просто просмотрите количество отключений в списке устройств.",
    device_mode_loading: "Загрузка, пожалуйста, подождите...",
    device_mode_control: "Контроль",
    device_mode_online: "Онлайн",
    device_mode_offline: "Офлайн",
    device_mode_scripe_1: "Подключено",
    device_mode_scripe_2: "Отключено",
    device_mode_scripe_3: "Подключить",
    device_mode_scripe_4: "Задержка",
    engine_control_title: 'Управление алгоритмами',
    engine_control_tag_1: 'Просмотр всех алгоритмов программного обеспечения, алгоритмы используются для вычислений и логики обработки соответствующих валют, при условии поддержки текущей версии, можно безболезненно обновить любую поддерживаемую валюту ядром текущей версии.',
    engine_control_tag_2: 'Движок автоматически обновляется через некоторое время. Обновление не повлияет на текущие процессы, вы можете смело использовать его. Вы также можете нажать кнопку [Быстрое обновление] ниже, чтобы выполнить обновление вручную.',
    engine_control_update: 'Быстрое обновление',
    engine_control_select: 'Пожалуйста, выберите',
    engine_control_all: 'Все алгоритмы',
    engine_control_script_1: 'Обновление выполнено успешно',
    engine_control_script_2: 'Горячее обновление алгоритма успешно выполнено, вы можете найти новую валюту при создании агента!',
    engine_control_script_3: 'Обновление не удалось',
    engine_control_script_4: 'Пожалуйста, не обновляйте часто',
    err_log_title: 'Журнал ошибок',
    export_config_title: 'Экспорт конфигурации агента',
    export_config_script_1: 'Экспорт конфигурационного файла порта {title}',
    export_config_json: 'Экспорт конфигурации JSON',
    hot_wallet_title: 'Настройка горячего кошелька',
    hot_wallet_desc: 'Здесь можно обновить параметры без влияния на нормальную работу порта. Если вы хотите изменить только настройки комиссии, это место будет лучшим местом для изменения.',
    hot_wallet_script_1: 'Портфель назначен - {wallet}',
    hot_wallet_fast: 'Быстрый выбор',
    hot_wallet_update: 'Обновить конфигурацию',
    hot_wallet_cancel: 'Отменить',
    hot_wallet_script_2: 'Пожалуйста, сначала выберите валюту для создания агента.',
    hot_wallet_script_3: 'Подсказка',
    hot_wallet_script_4: 'Если конфигурация сброса долго не применяется, вы можете удалить кошелек здесь и создать его заново. Оборудование не будет отключено и останется в работе.',
    hot_wallet_script_5: 'Удаление кошелька {wallet} выполнено успешно',
    hot_wallet_script_6: 'Ошибка при удалении кошелька {wallet}',
    hot_wallet_script_7: 'Ошибка проверки параметров конфигурации',
    hot_wallet_script_8: 'Обновление кошелька {wallet} выполнено успешно',
    hot_wallet_script_9: 'Ошибка при обновлении кошелька {wallet}',
    hot_wallet_script_10: 'Добавление кошелька {wallet} выполнено успешно',
    hot_wallet_script_11: 'Ошибка при добавлении кошелька {wallet}',
    hot_wallet_script_12: 'Ошибка проверки адреса кошелька {wallet}',
    hot_wallet_script_13: 'Ошибка проверки имени оборудования {wallet}',
    hot_wallet_script_14: 'Ошибка проверки адреса пула кошелька {wallet}',
    import_config_title: 'Импорт конфигурации агента',
    import_config_json: 'Импорт файла конфигурации JSON',
    import_config_excel: 'Импорт файла конфигурации порта Excel',
    import_config_kt: 'Импорт файла конфигурации порта KTMinerProxy',
    import_config_script_1: 'Порт',
    import_config_script_2: 'Ошибка',
    import_config_script_3: 'Ошибка разбора данных файла Excel - {error}, проверьте правильность формата шаблона',
    import_config_script_4: 'Ошибка формата файла Excel, в настоящее время поддерживаются только файлы в формате xlsx',
    import_config_script_5: 'Недопустимые параметры, проверьте формат данных шаблона',
    import_config_script_6: 'Порт уже существует, проверьте, не является ли он дубликатом',
    import_config_script_7: 'Ошибка параметров кошелька, проверьте формат данных шаблона',
    import_config_script_8: 'Импорт выполнен успешно',
    import_config_script_9: 'Ошибка импорта файла, попробуйте еще раз',
    import_config_script_10: 'Не классифицировано',
    import_config_script_11: 'Импорт порта {port} не удался, категория не найдена',
    import_config_script_12: 'Порт уже используется',
    import_config_script_13: 'Импорт порта {port} не удался. {error}',
    import_config_script_14: 'Импорт порта выполнен успешно',
    import_config_script_15: 'Импорт порта не удался',
    import_config_script_16: 'Импорт порта {port} не удался, rustminer временно не поддерживает валюту {coin}, этот импорт порта будет проигнорирован.',
    ip_alert_p: 'Выберите действие для {ip}.',
    ip_alert_add: 'Добавить в черный список',
    ip_alert_relieve: 'Удалить из черного списка',
    ip_alert_area: 'Посмотреть местоположение IP',
    ip_alert_script_1: 'Добавление в черный список выполнено успешно! Вы можете просмотреть или удалить его в Настройки -> Черный список IP.',
    ip_alert_script_2: 'Добавление в черный список не удалось!',
    ip_alert_script_3: 'Удаление из черного списка выполнено успешно!',
    ip_alert_script_4: 'Удаление из черного списка не удалось!',
    ip_ban_title: 'Черный список IP',
    ip_ban_loading: 'Загрузка...',
    ip_ban_p: 'Здесь отображаются добавленные в черный список IP-адреса. Щелкните по IP-адресу, чтобы выполнить соответствующие операции (также можно выполнить быстрое действие по IP-адресу в журнале IP или журнале портов).',
    ip_ban_tips: 'Введите IP-адрес, который хотите заблокировать для доступа к портам агента',
    ip_ban_add: 'Добавить IP',
    ip_ban_click: 'Щелкните по IP для выполнения операции',
    ip_ban_script_1: 'Добавление в черный список выполнено успешно!',
    ip_ban_script_2: 'Добавление в черный список не удалось!',
    ip_ban_script_3: 'Ошибка получения списка IP.',
    ip_log_title: 'Журнал доступа по IP',
    ip_log_loading: 'Загрузка...',
    ip_log_p: 'Показываются последние 100 записей доступа по IP. Щелкните по IP-адресу, чтобы выполнить соответствующие операции.',
    ip_log_time: 'Дата:',
    ip_log_script_1: 'Ошибка получения списка IP.',
    offline_push_title: 'Уведомления о потере связи',
    offline_push_p: 'Настройки потери связи',
    offline_push_tips: 'Уведомлять о потере связи с майнером:',
    offline_push_p_1: 'Уведомления о потере связи с отдельным устройством. Например, если майнер A потеряет связь, будет отправлено уведомление о потере связи с майнером A.',
    offline_push_label_1: 'Уведомлять при изменении хешрейта порта (в процентах):',
    offline_push_select: 'Пожалуйста, выберите',
    offline_push_close: 'Отключить уведомления о колебаниях хешрейта',
    offline_push_down: 'Уведомлять о снижении хешрейта на {ratio}%',
    offline_push_tips_1: 'Уведомления о колебаниях хешрейта порта. Если текущий хешрейт ниже предыдущего за последние 15 минут на указанный процент, будет отправлено уведомление.',
    offline_push_server: 'Сервис уведомлений Server酱',
    offline_push_server_p: 'Привяжите ключ уведомлений Server酱 для быстрой отправки информации о потере связи на ваш телефон через WeChat. Нажмите на логотип слева, чтобы узнать подробности о сервисе.',
    offline_push_label_2: 'Уведомлять о потере связи с майнером:',
    offline_push_label_3: 'Введите SendKey',
    offline_push_save: 'Сохранить настройки',
    offline_push_script_1: 'Ошибка получения конфигурации',
    offline_push_script_2: 'Настройки успешно сохранены',
    offline_push_script_3: 'Ошибка сохранения настроек',
    restart_model_title: 'Перезапуск сервиса',
    restart_model_p_1: 'Для Linux выполните сценарий установки и выберите опцию перезапуска.',
    restart_model_p_2: 'Для Windows просто закройте программу и снова откройте ее.',
    rms_loading: 'Регистрация в RMS впервые...',
    rms_tips_1: 'Протокол RMS позволяет эффективно сжимать и шифровать данные майнера в процессе передачи к {title}, сокращает объем передаваемых данных и количество соединений через публичную сеть, уменьшает использование локальной полосы пропускания и минимизирует задержку, а также защищает от атак посредников.',
    rms_down: 'Скачать:',
    rms_course: 'Руководство:',
    rms_push: 'Настройка адреса получателя',
    rms_tips_2: '● Вставьте этот зашифрованный текст в локальном клиенте RMS, чтобы связаться с сервером и получить соответствующую конфигурацию. (Если вы изменили порт веб-доступа, доменное имя или IP-адрес, эта информация также будет изменена, и локальному клиенту RMS потребуется повторное введение).',
    rms_config_title: 'Быстрая настройка',
    rms_pass_label: 'Пароль',
    rms_pass_place: 'Не обязательно',
    rms_p_1: '● Пароль будет использоваться как один из параметров шифрования клиента для защиты данных и обеспечения безопасности.',
    rms_port_label: 'Отображение порта',
    rms_port_default: 'По умолчанию',
    rms_port_custom: 'Пользовательский',
    rms_none: 'Порты RMS не настроены, сначала установите порты RMS',
    rms_label_1: 'Порт сервера RMS для прослушивания',
    rms_label_2: 'Порт клиента RMS для прослушивания локально',
    rms_tips_3: '● В режиме по умолчанию клиент RMS открывает все порты на сервере для прослушивания. Например, если на сервере открыты порты RMS 3333, 3334 и 3335, то клиент RMS локально также откроет эти три порта для отображения соответствующих портов на сервере, что просто и удобно.',
    rms_tips_4: '● В пользовательском режиме можно изменить порты, которые открывает локальный клиент RMS.',
    rms_label_3: 'Классификация клиента',
    rms_label_3_p: 'Для автоматической классификации адресов клиентов укажите информацию о классификации и заголовке, например, если указать классификацию порта как BTC-FishPool3306, в клиенте отобразится опция BTC, а внутри нее – соединение с заголовком FishPool3306. (Обратите внимание, что для разделения используйте дефисы, а не тире).',
    rms_other: 'Другие настройки',
    rms_label_4: 'Имя клиента',
    rms_label_4_place: 'Отображаемое название программного обеспечения в клиенте',
    rms_label_5: 'Объявление',
    rms_label_5_place: 'Информация для отображения в клиенте',
    rms_label_6: 'Дополнительная информация',
    rms_label_6_place: 'Дополнительная информация для отображения в клиенте',
    rms_update: 'Обновить конфигурацию',
    rms_script_1: 'Обновление выполнено успешно!',
    rms_script_2: 'Ошибка обновления!',
    rms_script_3: 'Регистрация службы не удалась.',
    run_log_title_1: 'Журнал выполнения',
    select_preset_pool_title: 'Выберите быстрый адрес пула',
    select_preset_pool_label_1: 'Предустановленный пул',
    select_preset_pool_label_2: 'Комментарий',
    select_preset_pool_label_3: 'Адрес пула',
    select_preset_pool_label_4: 'Протокол',
    select_preset_pool_label_5: 'Действие',
    select_preset_pool_label_6: 'Собственный пул',
    select_preset_pool_label_7: 'Комментарий',
    select_preset_pool_label_8: 'Адрес пула',
    select_preset_pool_label_9: 'Протокол',
    select_preset_pool_label_10: 'Действие',
    select_preset_pool_p_1: '- Здесь удобно выбирать адреса пулов, вы также можете добавить собственные адреса пулов в разделе Настройки -> Добавить быстрый адрес пула.',
    select_preset_pool_empty: 'Нет предустановленных пулов {selectCoin}, обратитесь к администратору для добавления. Предустановленные пулы могут быть обновлены.',
    select_preset_pool_empty_1: 'Нет собственных пулов {selectCoin}.',
    select_preset_pool_select: 'Выбрать',
    select_preset_pool_script_1: 'Ошибка получения предустановленных адресов пулов.',
    select_preset_pool_script_2: 'Ошибка получения данных.',
    select_preset_pool_script_3: 'Прямая передача',
    d_l_label_1: 'Список кошельков',
    d_l_label_2: 'Избранное',
    d_l_label_3: 'Адрес кошелька',
    d_l_label_4: 'Активные устройства',
    d_l_label_5: 'Неактивные устройства',
    d_l_label_6: 'Реальный хешрейт',
    d_l_label_7: 'Процент абзата',
    d_l_label_8: 'Действие',
    d_l_label_9: 'Управление устройствами',
    d_l_label_10: 'Название устройства',
    d_l_label_11: 'Адрес кошелька',
    d_l_label_12: 'Время онлайн',
    d_l_label_13: 'Эффективность',
    d_l_label_14: 'Процент абзата',
    d_l_label_15: 'Количество переподключений устройства',
    d_l_label_16: 'Задержка',
    d_l_label_17: 'Действие',
    d_l_label_18: 'Журнал порта',
    d_l_label_19: 'Протокол порта',
    d_l_label_20: 'Текущие активные устройства',
    d_l_label_21: 'Текущие неактивные устройства',
    d_l_label_22: 'Параллельные соединения',
    d_l_label_23: 'Номер порта',
    d_l_label_24: 'Валюта',
    d_l_label_25: 'Алгоритм',
    d_l_label_26: 'Максимальное количество подключений',
    d_l_label_27: 'Адрес подключения устройства',
    d_l_label_28: 'Адрес агента',
    d_l_label_29: 'Резервный адрес подключения',
    d_l_label_30: 'Процент абзата',
    d_l_label_31: 'Кошелек для абзата',
    d_l_label_32: 'Консоль',
    d_l_content_1: 'После входа на эту страницу по умолчанию отображается список кошельков, закрытие этого состояния приводит к отображению списка майнеров по умолчанию.',
    d_l_content_2: 'Здесь отображается активная мощность клиентских пулов и пулов с комиссией за последние 15 минут.',
    d_l_content_3: 'Эффективная доля работы устройства',
    d_l_content_4: 'Это устройство имеет отдельно установленную долю.',
    d_l_content_5: 'Количество повторных подключений устройства. Если их слишком много, пожалуйста, проверьте сетевую среду верхнего уровня, состояние майнера и другие компоненты на предмет проблем.',
    d_l_content_6: 'Просмотреть сведения об устройстве',
    d_l_content_7: 'Отдельно настроить долю этого майнера',
    d_l_place_1: 'Фильтрация по кошелькам, можно выбрать несколько',
    d_l_p_1: 'Все кошельки',
    d_l_p_2: 'Избранные кошельки',
    d_l_p_3: 'Просмотреть все устройства кошелька',
    d_l_p_4: 'Настроить комиссию для этого кошелька',
    d_l_p_5: 'Добавить в избранное',
    d_l_p_6: 'Удалить из избранного',
    d_l_p_7: 'Все устройства',
    d_l_p_8: 'Онлайн-устройства',
    d_l_p_9: 'Офлайн-устройства',
    d_l_p_10: 'Все кошельки',
    d_l_p_11: 'Кошелек:',
    d_l_p_12: 'Все устройства',
    d_l_p_13: 'Онлайн-устройства',
    d_l_p_14: 'Офлайн-устройства',
    d_l_p_15: 'Найдено {var_1} устройств, онлайн {var_2}, офлайн {var_3}, текущая активная мощность {var_4}',
    d_l_p_16: 'Найдено {var_1} онлайн-устройств, текущая активная мощность {var_2}',
    d_l_p_17: 'Найдено {var_1} офлайн-устройств, текущая активная мощность {var_2}',
    d_l_p_18: 'Имя устройства не настроено',
    d_l_p_19: 'Мощность',
    d_l_p_20: 'Эффективность',
    d_l_p_21: 'Доля',
    d_l_p_22: 'Количество повторных подключений',
    d_l_p_23: 'Задержка',
    d_l_p_24: 'Имя устройства для откачки:',
    d_l_p_25: 'Кошелек отсутствует на этом порту, поэтому нельзя отдельно настроить долю для этого устройства.',
    d_l_p_26: 'Журнал подключения устройства',
    d_l_p_27: 'Нет журнала подключений',
    d_l_p_28: 'Адрес кошелька',
    d_l_p_29: 'Имя устройства',
    d_l_p_30: 'Журнал порта',
    d_l_p_script_1: 'Доля установлена отдельно',
    d_l_p_script_2: 'Кошелек перенаправлен',
    d_l_p_script_3: 'Эффективный',
    d_l_p_script_4: 'Совместимый',
    d_l_p_script_5: 'Пул',
    d_l_p_script_6: 'Режим эффективности (автоматическая оптимизация включена)',
    d_l_p_script_7: 'Режим совместимости',
    d_l_p_script_8: 'Режим пула',
    d_l_p_script_9: 'Задержка',
    d_l_p_script_10: 'Удалить из избранного',
    d_l_p_script_11: 'Успешно добавлено в избранное',
    d_l_p_script_12: 'Не удалось добавить в избранное!',
    d_l_p_script_13: 'Не удалось получить журнал порта.',
    d_l_p_script_14: 'Подключено.',
    d_l_p_script_15: 'Отключено.',
    d_l_p_script_16: 'Устройство',
    d_l_p_script_17: 'Доступ с черного списка IP, отклонен.',
    d_l_p_script_18: 'Доступ с черного списка кошелька {ip} отклонен',
    d_l_p_script_19: 'Действие',
    d_l_p_script_20: 'Запрос не удался',
    d_l_p_script_21: 'Данные об устройстве не найдены, попробуйте обновить страницу.',
    d_l_p_script_22: 'Не удалось получить список портов кошелька.',
    d_l_p_script_23: 'Не удалось получить данные',
    d_l_p_script_24: 'Не удалось получить список',
    select_perset_wallet_title: 'Выберите адреса кошельков',
    select_perset_wallet_label_1: 'Пользовательский кошелек',
    select_perset_wallet_label_2: 'Примечание',
    select_perset_wallet_label_3: 'Адрес кошелька',
    select_perset_wallet_label_4: 'Действие',
    select_perset_wallet_empty: 'Пользовательские кошельки отсутствуют',
    select_perset_wallet_p_1: '- Здесь можно удобно выбрать адреса кошельков, вы также можете добавить пользовательские адреса в разделе Настройки - Добавить пользовательские адреса кошельков.',
    select_perset_wallet_p_2: '- Предпросмотр кошелька',
    select_perset_wallet_p_3: 'Выбрать',
    select_perset_wallet_error: 'Не удалось получить данные',
    set_https_title: 'Настройка доступа к веб-интерфейсу по HTTPS',
    set_https_label_1: 'Включить доступ по HTTPS',
    set_https_p_1: 'Установите, использует ли веб-интерфейс https для доступа. После установки необходимо перезапустить программу, чтобы изменения вступили в силу. Для Linux можно выполнить скрипт установки для перезапуска, для Windows просто закройте и снова откройте программу. (Примечание: не открывайте интерфейс с разными протоколами, в противном случае интерфейс не будет доступен. Если вы используете клиент RMS, после перезапуска откройте Настройки - RMS, нажмите Обновить конфигурацию, чтобы получить новый адрес для RMS, старый адрес станет недействительным).',
    set_https_sciprt_1: 'Не удалось получить статус HTTPS.',
    set_https_sciprt_2: 'Пожалуйста, обратите внимание',
    set_https_sciprt_3: `
        Настройка HTTPS выполнена успешно, <span style="color:red;">необходимо перезапустить</span> программу для вступления изменений в силу. После перезапуска вручную введите префикс <span style="color:red;">https://</span> для доступа, в противном случае некоторые браузеры могут запросить кэшированное содержимое, из-за чего доступ к интерфейсу станет невозможным. <br><br>
        Если вы используете клиент <span style="color:red;">RMS</span>, после перезапуска откройте Настройки-RMS, нажмите Обновить конфигурацию, чтобы получить последний адрес RMS, старый адрес станет недействительным.
    `,
    set_https_sciprt_4: `
        Настройка HTTP выполнена успешно, <span style="color:red;">необходимо перезапустить</span> программу для вступления изменений в силу. После перезапуска вручную введите префикс <span style="color:red;">https://</span> для доступа, в противном случае некоторые браузеры могут запросить кэшированное содержимое, из-за чего доступ к интерфейсу станет невозможным. <br><br>
        Если вы используете клиент <span style="color:red;">RMS</span>, после перезапуска откройте Настройки-RMS, нажмите Обновить конфигурацию, чтобы получить последний адрес RMS, старый адрес станет недействительным.
    `,
    set_https_sciprt_5: 'Не удалось установить статус HTTPS.',
    set_kenc_key_title: 'Настройка пользовательского ключа KENC',
    set_kenc_p_1: 'Эта функция предназначена для продвинутых пользователей и используется для собственного шифрования клиента на локальном компьютере. Тип шифрования: RSA128. Если вы не понимаете назначение этой функции, не устанавливайте ее, иначе подключение к порту KENC может стать невозможным.',
    set_kenc_p_2: 'Сохранить изменения',
    set_kenc_p_3: 'Восстановить по умолчанию (не использовать пользовательские настройки)',
    set_kenc_label_1: 'Пароль',
    set_kenc_label_2: 'Ключ',
    set_kenc_place_1: 'Создайте ключ на основе этого пароля.',
    set_kenc_place_2: 'Ключ будет сгенерирован автоматически после ввода пароля.',
    set_kenc_script_1: 'Успешное восстановление.',
    set_kenc_script_2: 'Успешная настройка.',
    set_port_model_title: 'Настройка порта веб-доступа',
    set_port_model_p_1: 'После изменения порта доступа к веб-интерфейсу программу необходимо перезапустить. Пожалуйста, используйте новый порт для доступа.',
    set_port_model_p_2: 'Порт доступа к веб-интерфейсу',
    set_port_model_p_3: 'Сохранить изменения',
    set_port_model_label_1: 'Номер порта',
    set_pump_by_w_title: 'Установка доли для всех устройств кошелька',
    set_pump_by_w_p_1: 'Отдельно настроить долю для всех устройств под этим кошельком. Не влияет на другие устройства. Если одновременно установлены отдельные доли для майнеров и кошельков, приоритет отдается отдельным долям майнеров > долям кошельков > долям портов.',
    set_pump_by_w_p_2: 'Сохранить настройки',
    set_pump_by_w_p_3: 'Восстановить настройки',
    set_pump_by_w_p_4: 'Отмена',
    set_pump_by_w_p_5: 'Отменить',
    set_pump_by_w_script_6: 'Обновление успешно, все устройства под кошельком {targetWallet} будут перенаправлены на кошелек {data.addr} !',
    set_pump_by_w_script_7: 'Установка не удалась!',
    set_pump_by_w_script_8: 'Восстановление выполнено успешно.',
    set_pump_by_w_script_9: 'Ошибка восстановления.',
    set_user_title: 'Изменение имени пользователя и пароля',
    set_user_p_1: 'После установки программы рекомендуется немедленно изменить имя пользователя и пароль по умолчанию, чтобы предотвратить вторжение. Не ждите взлома и <span style="color: red;">не раскаивайтесь</span> после него. (Имя пользователя также можно изменить)',
    set_user_p_2: 'Текущая информация об учетной записи',
    set_user_p_3: 'Изменить информацию',
    set_user_p_4: 'Сохранить изменения',
    set_user_place_1: 'Текущее имя пользователя',
    set_user_place_2: 'Текущий пароль',
    set_user_place_3: 'Новое имя пользователя',
    set_user_place_4: 'Подтвердить новое имя пользователя',
    set_user_place_5: 'Новый пароль',
    set_user_place_6: 'Подтвердить новый пароль',
    set_user_script_1: 'Изменение не удалось',
    set_user_script_2: 'Проверка настроек не пройдена',
    set_user_script_3: 'Изменения успешно сохранены!',
    set_worker_ratio_title: 'Отдельная настройка доли',
    set_worker_ratio_p_1: 'Отдельно настроить долю для этого устройства. Не влияет на другие устройства.',
    set_worker_ratio_p_2: 'Сохранить настройки',
    set_worker_ratio_p_3: 'Восстановить настройки',
    set_worker_ratio_p_4: 'Отмена',
    set_worker_ratio_script_1: 'Подсказка',
    set_worker_ratio_script_2: 'Отдельная настройка доли для майнера действует только если на кошельке, привязанном к порту, установлена доля. Если на порту не нужна доля, можно установить долю кошелька на 0.01.',
    set_worker_ratio_script_3: 'Не удалось получить долю.',
    set_worker_ratio_script_4: 'Успешная настройка отдельной доли!',
    set_worker_ratio_script_5: 'Ошибка настройки отдельной доли!',
    set_worker_ratio_script_6: 'Очистка выполнена успешно!',
    set_worker_ratio_script_7: 'Ошибка очистки!',
    set_upload_cert_title: 'Глобальные сертификаты TLS/SSL',
    set_upload_cert_p_1: 'В настоящее время используется',
    set_upload_cert_p_2: 'сертификат.',
    set_upload_cert_p_3: 'Выберите файл сертификата crt или pem',
    set_upload_cert_p_4: 'Выберите файл ключа сертификата',
    set_upload_cert_p_5: 'Предварительный просмотр crt, pem',
    set_upload_cert_p_6: 'Предварительный просмотр ключа',
    set_upload_cert_p_7: 'Сохранить и использовать пользовательский сертификат',
    set_upload_cert_p_8: 'Восстановить встроенный сертификат',
    set_upload_cert_script_1: 'Используется встроенный сертификат',
    set_upload_cert_script_2: 'Используется пользовательский сертификат',
    set_upload_cert_script_3: 'Встроенный',
    set_upload_cert_script_4: 'Пользовательский',
    set_upload_cert_script_5: 'Ошибка',
    set_upload_cert_script_6: 'Не удалось правильно настроить сертификат',
    set_upload_cert_script_7: 'Успешное сохранение!',
    set_upload_cert_script_8: 'Восстановление выполнено успешно!',
    uuid_model_title: 'Посмотреть UUID на этом устройстве',
    version_log_title: 'Журнал обновлений',
    version_log_p_1: 'Текущая версия программы:',
    version_log_p_2: 'Последняя версия программы:',
    version_log_p_3: 'Получение информации...',
    version_log_script_1: 'Не удалось получить журнал обновлений!',
    wallet_alert_p_1: 'Выберите действие для кошелька {wallet}.',
    wallet_alert_p_2: 'Добавить в черный список',
    wallet_alert_p_3: 'Убрать из черного списка',
    wallet_alert_script_1: 'Кошелек успешно добавлен в черный список! Вы можете просмотреть или удалить его в Настройках -> Черный список кошельков.',
    wallet_alert_script_2: 'Не удалось добавить кошелек в черный список!',
    wallet_alert_script_3: 'Кошелек успешно удален из черного списка!',
    wallet_alert_script_4: 'Не удалось удалить кошелек из черного списка!',
    wallet_ban_title: 'Черный список кошельков',
    wallet_ban_p_1: 'Загрузка списка...',
    wallet_ban_p_2: 'Здесь отображены кошельки, добавленные в черный список. Нажмите на кошелек для выполнения соответствующего действия (в Логах портов также можно быстро выполнить действия для соответствующего кошелька).',
    wallet_ban_p_3: 'Добавить кошелек',
    wallet_ban_p_4: 'Адрес кошелька:',
    wallet_ban_p_5: 'Нажмите на адрес кошелька для выполнения действий',
    wallet_ban_place: 'Введите адрес кошелька для добавления в черный список',
    wallet_ban_script_1: 'Кошелек успешно добавлен в черный список!',
    wallet_ban_script_2: 'Не удалось добавить кошелек в черный список!',
    wallet_ban_script_3: 'Не удалось получить список кошельков.',
    who_am_p_1: 'Бесплатный доступ к кастомизированной версии:',
    who_am_p_2: 'Подтверждаю и утверждаю здесь:',
    who_am_p_3: '1. RustMinerSystem подчиняется законам Гонконга. Обратите внимание, что требования различных стран/регионов могут ограничивать такие продукты и услуги.',
    who_am_p_4: '2. Поэтому эти продукты и услуги, а также некоторые функции могут быть недоступны или ограничены в определенных юрисдикциях или регионах или для некоторых пользователей.',
    who_am_p_5: '3. Этот продукт не является VPN-подобным продуктом, поскольку он не может обеспечить доступ к запрещенному содержимому в регионах, где это запрещено.',
    who_am_p_6: '4. Этот продукт представляет собой программное обеспечение для управления майнинговыми устройствами и фермами, не получающее данные майнинговых устройств незаконным образом. Все устройства, подключаемые к программе, должны быть настроены владельцами устройств для подключения к майнингу, чтобы обеспечить осведомленность любого клиента, использующего эту программу.',
    who_am_p_7: '5. Вы не включены в списки организаций, занимающихся террористической деятельностью, и лиц, участвующих в террористической деятельности, перечисленные в резолюциях Совета Безопасности ООН.',
    who_am_p_8: '6. Вас не ограничивают или не запрещают использовать эту программу административные или правоохранительные органы какой-либо страны или региона.',
    who_am_p_9: '7. Вы не являетесь резидентом Кубы, Ирана, Северной Кореи, Сирии и других стран или регионов, подвергшихся санкциям со стороны правительства соответствующих стран или международных организаций.',
    who_am_p_10: '8. Вы не являетесь резидентом страны или региона, где деятельность с использованием цифровых валют запрещена или ограничена, включая, помимо прочего, материковую часть Китая и т.д.',
    who_am_p_11: '9. При использовании услуг, предоставляемых этой программой, вы соблюдаете соответствующие законы и политику в вашей стране или регионе.',
    who_am_p_12: '10. Вы соглашаетесь: если из-за законов и политики вашей страны или региона или по каким-либо другим применимым законам использование услуг этой программы является незаконным, вы принимаете на себя все соответствующие юридические риски и ответственность, безоговорочно и окончательно отказываясь от права предъявлять какие-либо претензии к этой программе.',
    who_am_p_13: '11. Вы должны понимать и соблюдать местные законы и нормативные акты. Используя этот продукт, вы подтверждаете свое согласие с вышеприведенными лицензионными соглашениями. Проблемы, связанные с законностью, возникающие из-за этого продукта, не имеют отношения к этому продукту.',
    who_am_p_14: '12. Особая благодарность:',
    top_panel_chart_title: 'Мощность пула',
    top_panel_p_1: 'Текущая мощность',
    top_panel_content_1: 'Изменить порт',
    top_panel_content_2: 'Плата за быструю обработку транзакций',
    top_panel_content_3: 'Остановить порт',
    top_panel_content_4: 'Запустить порт',
    top_panel_content_5: 'Удалить порт',
    top_panel_script_1: 'Порт активен',
    top_panel_script_2: 'Порт отключен',
    top_panel_script_3: 'Очередь, пожалуйста, подождите...',
    top_panel_script_4: 'Отключить порт',
    top_panel_script_5: 'Вы уверены, что хотите отключить порт? Устройства, работающие на этом порту, будут отключены.',
    top_panel_script_6: 'Предупреждение',
    top_panel_script_7: 'Единица расчета хэшрейта для этой валюты не установлена и не влияет на логику отбора или реальную работу.',
    top_panel_script_8: 'LTC лучше всего майнить на пуле viabtc, чтобы получить более точный хэшрейт.',
    top_panel_script_9: 'Обратите внимание, что рекомендуется майнить на herominer для монеты alph, так как у других пулов есть проблемы с уровнем сложности. Если вы хотите майнить на другом пуле, используйте чистый перенаправленный сервер nginx.',
    top_panel_script_10: 'Не используйте bzminer для майнинга RXD!',
    top_panel_script_11: '[BTC] Если в порту есть устройства, не поддерживающие кросспуловое майнинговое, фон соответствующего устройства будет желтым, и система автоматически маршрутизирует их к пулу-посреднику, обычно это касается только старых моделей устройств.',
    top_panel_script_12: 'Для этой валюты включен механизм защиты хэшрейта клиента. Чтобы минимизировать потери клиентов, мы стремимся забирать как можно больше хэшрейта до достижения критического значения, при котором клиент начнет терять хэшрейт. Поэтому иногда может возникать ситуация, когда хэшрейт будет ниже. Если вы хотите отключить эту функцию, измените настройки порта в редакторе.',
    auth_config_label_1: 'Принудительная отправка успешных шаров',
    auth_config_content_1: 'Если отправка не удалась, все равно отправлять устройству успешный ответ, чтобы на устройстве или ядре отображался 100% успех.',
    deal_config_p_1: 'Протокол порта',
    deal_config_p_2: 'Совместимый с устаревшими клиентами KT локальный протокол. Клиенты KENC могут напрямую подключаться здесь.',
    deal_config_p_3: 'В порт можно подключаться по различным протоколам, устройства могут использовать как TCP, так и SSL для подключения к этому порту.',
    deal_config_p_4: 'Двойной протокол TCP/TLS',
    default_config_p_1: 'Оптимизация для OKMiner:',
    default_config_p_2: 'Включить',
    default_config_p_3: 'Отключить',
    default_config_p_4: 'Адрес mallob:',
    default_config_p_5: 'Защита хэшрейта:',
    default_config_p_6: 'Быстрый выбор',
    default_config_p_7: 'Ожидание ввода',
    default_config_content_1: 'Оптимизация для устройств, которые не могут подключиться к OKMiner.',
    default_config_content_2: 'Максимальная защита хэшрейта клиентов. При включении может наблюдаться недостаточная отдача, так как мы стремимся забирать как можно больше хэшрейта до достижения критического значения, при котором клиент начнет терять хэшрейт. Однако при этом хэшрейт клиента не пострадает. Если эта функция отключена, то ставится цель точного сбора данных по хэшрейту, но не предпринимаются меры защиты хэшрейта клиента. Решение о включении или отключении следует принимать самостоятельно.',
    default_config_place_1: 'Максимальное 65535',
    default_config_script_1: 'Сначала выберите монету для создания порта.',
    default_config_script_2: 'Чистый ретранслятор',
    default_config_script_3: 'Обратите внимание',
    default_config_script_4: `
        ** Обратите внимание на версию локального майнингового ядра **
        <br><br>
        Официально объявлено, что DNX прекратила проверку майнинга, больше не требуется настройка адреса майнинга, используйте последнее майнинговое ядро для майнинга.
        <br><br>
        Хотя проверка была отключена, похоже, что официально ее еще не отключили в установленное время, пожалуйста, дождитесь уведомлений от пула и официального источника, прежде чем включать комиссию. Программа по-прежнему сохраняет прокси-переадресацию для майнинга, ваш адрес майнинга:
        <br><br>
        {mobURL}
        <br><br>
        Если вы еще не обновились, убедитесь, что ваш майнер обновлен до последней версии (OZM 1.2.5 или SRB 2.3.9), официально с 21 октября с 04:00 прекращается поддержка старых версий (похоже, что это еще не вступило в силу). Благодарим за вашу поддержку!
        <br><br>
        Nvidia: <a href="https://github.com/OneZeroMiner/onezerominer/releases" target="_blank">OneZeroMiner</a><br>
        AMD: <a href="https://github.com/doktor83/SRBMiner-Multi/releases" target="_blank">RBMiner</a><br> 
        Не используйте ядро DynexSolve для майнинга.
    `,
    default_config_script_5: 'Внимание',
    default_config_script_6: 'Создание чистого переадресационного порта, этот порт действует только на конфигурации [номер порта], [максимальное количество соединений], [конфигурация порта], [адрес и протокол прокси-пула], все остальные настройки не применяются, не включают в себя статистику данных и комиссионные сборы и т. д., этот порт можно использовать на любом устройстве любой монеты и модели.',
    default_config_script_7: 'Не сливайте LTC в Fish Pool, лучший выбор - переливать в Viabtc или другие пулы, переливание между пулами не влияет на клиентов, Fish Pool может столкнуться с серьезными проблемами недостаточного слива, из-за причин пула.',
    default_config_script_8: 'Обратите внимание, рекомендуется использовать herominer для майнинга alph, в других пулах существуют проблемы с diff, если вы хотите работать с другими пулами, рекомендуется использовать чистый переадресующий сервер nginx.',
    default_config_script_9: 'Пожалуйста, не используйте bzminer для майнинга RXD!!!',
    pump_config_p_1: 'Быстрый выбор',
    pump_config_script_1: 'Пожалуйста, сначала выберите монету для создания прокси.',
    pump_config_script_2: 'Я понимаю, что разные адреса прокси-пула и комиссионного пула могут привести к ненормальной работе оборудования',
    pump_config_script_3: 'Добавление не удалось',
    pump_config_script_4: 'Можно добавить не более десяти кошельков',
    client_con_label_1: 'Версия клиента',
    client_con_label_2: 'Время работы программы',
    client_con_label_3: 'Примечание',
    client_con_label_4: 'Хешрейт',
    client_con_label_5: 'Онлайн / Офлайн',
    client_con_label_6: 'Все',
    client_con_label_7: 'CPU / Память',
    client_con_label_8: 'Вверх / Вниз',
    client_con_label_9: 'Действие',
    client_con_empty_1: 'Нет добавленных клиентов.',
    client_con_place_1: 'Пожалуйста, выберите',
    client_con_p_1: 'Введите API других {CONFIG.TITLE} здесь, это удобно для общего наблюдения и управления.',
    client_con_p_2: 'API на этом устройстве:',
    client_con_p_3: 'Обновить API',
    client_con_p_4: 'После обновления API на этом устройстве старое API станет недействительным, контроллеру необходимо будет повторно ввести API для управляемых клиентов.',
    client_con_p_5: '+ Добавить клиента',
    client_con_p_6: 'Версия',
    client_con_p_7: 'Время работы программы',
    client_con_p_8: 'Примечание',
    client_con_p_9: 'Хешрейт в реальном времени',
    client_con_p_10: 'Онлайн / Офлайн',
    client_con_p_11: 'CPU / Память',
    client_con_p_12: 'Вверх / Вниз',
    client_con_p_13: 'Быстрый просмотр',
    client_con_p_14: 'Удаленный доступ',
    client_con_p_15: 'Редактировать',
    client_con_p_16: 'Удалить',
    client_con_script_1: 'Получение...',
    client_con_script_2: 'Не удалось получить',
    client_con_script_3: 'Ошибка обновления токена!!!',
    client_con_script_4: 'Удалить этот элемент',
    client_con_script_5: 'Вы уверены, что хотите удалить этот элемент?',
    client_con_script_6: 'Успешно удалено',
    client_con_script_7: 'Не удалось удалить',
    client_con_script_8: 'Не удалось получить API!!!',
    client_con_script_9: 'Пока не загружено, пожалуйста, обновите позже.',
    client_con_script_10: 'Не удалось получить список.',
    client_con_script_11: 'Активные устройства',
    client_con_script_12: 'Неактивные устройства',
    client_con_script_13: 'Память',
    client_con_script_14: 'ЦП',
    client_con_script_15: 'Получение',
    client_con_script_16: 'Отправка',
    login_view_script_1: 'Забыли пароль?',
    login_view_script_2: `
        Если вы забыли пароль, на Linux можно запустить скрипт установки, выбрав опцию сброса пароля, чтобы восстановить исходный пароль.
        <br><br>
        Если вы используете Windows, после завершения работы программы вручную удалите все файлы, начинающиеся с 0 в каталоге программы, и снова запустите программу, чтобы восстановить исходные учетные данные.
        <br><br>
        Исходные учетные данные:
        <br><br>
        qzpm19kkx xloqslz913
    `,
    login_view_script_3: 'Проверка информации не удалась!',
    login_view_script_4: 'Успешный вход!',
    port_inside_script_1: 'Подождите...',
    port_inside_script_2: 'Открыть порт',
    port_inside_script_3: 'Закрыть порт',
    port_inside_script_4: 'Открыть порт',
    port_inside_script_5: 'Порт успешно закрыт',
    port_inside_script_6: 'Не удалось закрыть порт',
    port_inside_script_7: 'Порт успешно открыт',
    port_inside_script_8: 'Не удалось открыть порт',
    port_inside_script_9: 'Удалить порт',
    port_inside_script_10: 'Вы уверены, что хотите удалить этот порт?',
    port_inside_script_11: 'Порт успешно удален',
    port_inside_script_12: 'Не удалось удалить порт',
    system_view_script_1: 'Обнаружено использование исходных учетных данных. Пожалуйста, сначала измените пароль аккаунта.',
    line_chart_3_script_1: 'Среднее количество часов：',
    set_safe_route: 'Установить безопасный маршрут',
    set_safe_p_1: 'Установите доступ к незащищенному маршруту, который знаете только вы, чтобы повысить безопасность и избежать сканирования фоновых процессов, чтобы предотвратить взлом методом подбора пароля. Обязательно выполните эту настройку.',
    set_safe_p_2: 'Пример:',
    set_safe_p_3: 'Предположим, что ваш адрес фоновой задачи - http://1.2.3.4:3333, а безопасный адрес установлен как nihao. После сохранения фоновая задача будет доступна только через http://1.2.3.4:3333/nihao/',
    set_safe_p_4: 'После установки маршрута программа будет автоматически перезапущена. Пользователи LINUX, установившие не через скрипт, обратите внимание на запуск программы. Пользователи WINDOWS после установки настройки должны запустить программу вручную.',
    set_safe_p_5: 'Незабудьте свой установленный безопасный маршрут. Если забыли, пользователи LINUX могут запустить скрипт установки, чтобы посмотреть маршрут, а пользователи WINDOWS могут найти файл rust-config в каталоге, в котором поле ROUTE содержит ваш пользовательский маршрут.',
    set_safe_p_6: 'В пользовательском безопасном маршруте не допускаются любые специальные символы, только английские буквы. Не используйте спецсимволы типа (/ // \\ ?), и т. д.',
    set_safe_p_7: 'Сохранить безопасный маршрут',
    set_safe_p_8: 'Внимание!! При настройке доступа после завершения, конец URL - адреса посещения должен быть в / конце, например http://a.b.com:1234/fuck/ Иначе не будет доступа к фону.',
    set_safe_label_1: 'Предварительный просмотр адреса фоновой задачи:',
    set_safe_label_2: 'Пользовательский безопасный маршрут:',
    set_safe_label_3: 'Безопасный маршрут не установлен. Введите его здесь.',
    set_safe_route_script_1: 'Предупреждение',
    set_safe_route_script_2: 'Вы не установили безопасный маршрут к фоновой задаче. Пожалуйста, перейдите в Меню -> Установить безопасный маршрут для настройки.',
    set_safe_route_script_3: 'Не удалось получить маршрут.',
    set_safe_route_script_4: 'Не допускается использование //',
    set_safe_route_script_5: 'Не допускается завершение /',
    set_safe_route_script_6: 'Не допускается начало /',
    set_safe_route_script_7: 'Не допускается использование ?',
    set_safe_route_script_8: 'Подтвердить конфигурацию',
    set_safe_route_script_9: `
    <p>Скоро адрес фоновой задачи изменится на:</p>
    <br>
    <p>
        <a href="{preview_url}" target="_blank">{preview_url}</a>    
    </p>
    <br>
    <p>Запомните его.</p>
    <br>
    <p>После подтверждения программа будет перезагружена. Пользователи LINUX, установившие не через скрипт, обратите внимание на запуск программы. Пользователи WINDOWS после установки настройки должны запустить программу вручную.</p>
    `,
    set_safe_route_script_10: 'Установка завершена',
    set_safe_route_script_11: 'Пожалуйста, подождите автоматического обновления. Пользователи WINDOWS, пожалуйста, перезапустите программу, после запуска программы вручную обратитесь по новому адресу.',
    auth_config_label_2: 'Старая оптимизация.',
    auth_config_content_2: 'Для шахтеров, которые не могут поддерживать откачку воды, эти шахты при откачке воды будут проходить через пул клиента для откачки воды, без этой функции, пожалуйста, выключите его.'
}