export const lang = {
    theme: 'Theme',
    logout: 'Logout',
    menu: {
        dashboards: 'Dashboard',
        poolproxy: 'Pool Proxy',
        totalproxy: 'View All Proxies',
        addproxy: 'Add New Proxy',
        importproxy: 'Import Proxy Configuration',
        exportproxy: 'Export Proxy Configuration',
        notproxy: 'Proxy for this currency not added',
        setting: 'Settings',
        setcert: 'Set Global TLS/SSL Certificate',
        setaccount: 'Change Username and Password',
        ipban: 'IP Blacklist',
        offline: 'Offline Alert',
        webport: 'Modify WEB Access Port',
        addwallet: 'Add Shortcut Wallet',
        addpool: 'Add Shortcut Mining Pool',
        uuid: 'View Local UUID',
        restart: 'Restart Software',
        log: 'Log Management',
        algo: 'Algorithm Engine',
        front: 'Front Proxy',
        group: 'Group Control',
        about: 'About Us',
        version: 'Software Version',
        error_log: 'View Error Log'
    },
    sun: 'Dark Theme',
    moon: 'Light Theme',
    proxy: {
        newproxy: 'Add new proxy',
        editproxy: 'Edit port',
        t_deal: 'Proxy protocol',
        t_default: 'Basic configuration',
        t_fee: 'Fee configuration',
        t_conn: 'Connection mode',
        t_replace: 'Replace specified wallet',
        create_proxy: 'Create proxy',
        tcp_intro: 'Plain text connection, faster transmission speed, and vulnerable to man-in-the-middle attacks.',
        ssl_intro: 'Certificate encryption, slightly affecting transmission efficiency (the built-in certificate is used by default. If you need to use a custom certificate, please go to Settings-Certificate Settings to upload the certificate).',
        rms_intro: 'Own protocol, can compress the number of devices and data transmission to this software, fast transmission speed, and cannot be attacked or forged request attacks by middlemen, but requires a front-end proxy client.',
        port_num: 'Port number',
        proxy_coin: 'Proxy coin',
        max_conn: 'Maximum connections',
        remark: 'Port remark',
        proxy_addr: 'Proxy mining pool address',
        proxy_addr_des: 'Enter or select the target mining pool address, such as btc.f2pool.com:1314',
        proxy_addr_deal: 'Mining pool protocol',
        conn_test: 'Connectivity test',
        conn_test_ok: 'Connection successful',
        conn_test_no: 'Connection failed',
        stand_pool: 'Standby mining pool address',
        stand_pool_des: 'Standby mining pool address. After setting, if the target mining pool encounters problems, it will automatically switch to the standby mining pool to work.',
        stand_pool_deal: 'Standby mining pool protocol',
        des_remark: 'Remark',
        new_wallet: 'Add new wallet',
        allot_wallet: 'Allocate wallet',
        fee_addr: 'Fee mining pool address',
        fee_addr_message: 'If the ratio is set, this item is required.',
        fee_addr_des: 'Extract fees in this mining pool address. It is recommended to be the same as the target mining pool address.',
        fee_deal: 'Fee mining pool protocol',
        wallet_addr: 'Wallet address or sub-account',
        wallet_perc: 'If the ratio is set, this item is required.',
        wallet_desc: 'If it is a dual-mining currency, the configuration format here should be wallet address.wallet address',
        fee_name: 'Fee name',
        fee_name_desc: 'Device name for extracting computing power.',
        fee_percent: 'Fee ratio (%)',
        delete_wallet: 'Delete Allocated Wallet',
        err_port: 'Please enter the port to be monitored, ranging from 1 to 65534',
        err_proxy_coin: 'Please select the currency to be proxy',
        err_pool_addr: 'Please enter or select the mining pool',
        err_pool_mode: 'This item is required in mining pool mode',
        conn_eff: 'Efficient mode',
        conn_eff_des: 'A complete connection mode and mechanism, almost no loss of extraction and non-disconnected connections, and automatically switch incompatible devices to compatible mode.',
        conn_com: 'Compatible mode',
        conn_com_des: 'Obsolete kernel or model and some mining pools with poor compatibility will cause excessive invalid computing power. At this time, this mode should be selected, which will cause a certain amount of loss.',
        conn_pool: 'Mining pool mode',
        conn_pool_des: 'Merge all mining pool connections, greatly reduce the bandwidth of mining pool direction, all miners will be merged under this mode, and wallet and device names will be unified, suitable for your own mining farm (use with caution).',
        unify_wallet: 'Unify Wallet',
        unify_wallet_des: 'Replace the wallet address on all devices.',
        unify_name: 'Unify Device Name',
        unify_name_des: 'Replace the name on all devices.',
        replace_err: 'Both options must be set or unset at the same time',
        ori_wallet: 'Original Wallet',
        ori_wallet_des: 'Replace the matching wallet address with the specified wallet address. Supports multiple wallet addresses in one line format.',
        target_wallet: 'Replace with',
        target_wallet_des: 'Replace the wallet address with the address here.',
        create_error: 'Creation failed',
        create_config_error: 'Configuration check failed. Please make the necessary changes as prompted.',
        alert_warning: 'Warning⚠️',
        alert_warning_text: 'The fee pool is set to the same address as the target pool by default. If you specify a separate fee pool, it may cause issues. If you really want to specify a separate fee pool address, please enter "I understand that the different addresses of agent pool and commission pool may cause the equipment to fail to work normally" in the input box below.',
        alert_warning_text2: 'At least one wallet configuration must be kept, even if no proportion is configured.',
        alert_warning_text3: 'This protocol is not open',
        alert_warning_text4: 'RMS client is currently under development, please wait a few days.'
    },
    router: {
        login: 'Login',
        dashboard: 'Dashboard',
        com: 'Statistics',
        pool_proxy: 'Pool Proxy'
    },
    cancel: 'Cancel',
    pleaseSelect: 'Please select',
    alert: {
        set_success: 'Set successfully',
        set_lang: 'Unswitched languages will take effect after the page is manually refreshed.'
    },
    hashrate: 'Hashrate',
    chart: {
        effect: 'Effective hashrate',
        invalid: 'Invalid hashrate',
        pump: 'Extraction hashrate',
        e_s: 'Effective shares',
        i_s: 'Invalid shares',
        p_s: 'Extraction shares'
    },
    eff_hash: 'Effective hashrate',
    eff_hash_r: 'Current effective hashrate',
    fuck_error: 'Fatal error, please contact the administrator.',
    nons_title: 'Not Open',
    nons_text: 'This feature is still under development, please wait a few more days.',
    attack: 'Attack interception',
    ping: 'Average latency',
    c_ping: 'Current average latency',
    count: 'times',
    desk_per: 'Miner Usage',
    desk_title: 'Disk Usage',
    desk_total: 'Total usage',
    desk_pro: 'Program usage',
    desk_res: 'Remaining available',
    login: {
        hello: 'Welcome to use 👋',
        message: 'Login to your account and start experiencing the best farm operation and maintenance tools.',
        user: 'Username',
        user_des: 'Please enter your username',
        pass: 'Password',
        pass_des: 'Please enter your password',
        verify: 'Verification code',
        verify_des: 'Please enter the picture content',
        reme: 'Remember account',
        forgot: 'Forgot Password?',
        login: 'Login'
    },
    logout_titme: 'Log out?',
    logout_text: 'The current login user will be logged out soon.',
    server_run_time: 'Program has been running:',
    notice: 'Notice:',
    add_proxy: 'Add new proxy',
    hash_ratio: 'Current hashrate',
    coin_active_rank: 'Device activity distribution',
    rank: 'Ranking',
    coin: 'Coin',
    hash: 'Hashrate',
    active_device: 'Active devices',
    active_device_r: 'Current active',
    die_device: 'Offline devices',
    current_die: 'Current offline',
    ratio: 'Ratio',
    get_config_error: 'Failed to get configuration information!',
    save_success: 'Saved successfully～',
    save_error: 'Save failed!',
    save_error_1: 'Failed to save configuration because the port was not closed.',
    create_success: 'Created successfully～',
    create_error: 'Creation failed!',
    update_config: 'Update configuration',
    pool_hash: 'Pool hashrate',
    system_log: 'Operation log',
    error_hash: 'Invalid hashrate',
    error_hash_ratio: 'Current invalid hashrate/ratio',
    net_stats: 'Traffic statistics',
    net_stats_des: 'Upstream/downstream resources consumed by the program',
    net_up: 'Upstream',
    net_down: 'Downstream',
    active_device_stats: 'Active device statistics',
    total_pool: 'All pools',
    active_device_ratio: 'Device activity rate',
    port_deal: 'Port protocol',
    port_des: 'Proxy port/remarks',
    port_line: 'Online/offline',
    current_hash: 'Current hashrate',
    port_ratio: 'Efficiency',
    connect_addr: 'Connection address',
    status: 'Status',
    opa: 'Operation',
    show_detail: 'View details',
    switch_port: 'Open/close port',
    edit_port: 'Edit port',
    hot_update_wallets: 'Hot update fee configuration',
    delete_port: 'Delete port',
    port_status_success: 'Port status normal',
    port_status_close: 'Port has been closed',
    port_status_queue: 'Queuing, please wait...',
    delete_port_aff: 'Are you sure you want to delete this port?',
    delete_port_ok: 'Successfully deleted port',
    delete_port_error: 'Failed to delete port',
    close_port_ok: 'Port closed successfully',
    close_port_error: 'Failed to close port',
    open_port_ok: 'Port opened successfully',
    open_port_error: 'Failed to open port',
    copy_conn: 'Connection address copied',
    real_stats: 'Real-time statistics',
    time_15_hash: '15-minute avg hashrate:',
    time_4_hash: '4-hour avg hashrate:',
    time_24_hash: '24-hour avg hashrate:',
    e: 'Effective',
    f: 'Invalid',
    c: 'Extract',
    real_ping: 'Average latency',
    ready_port: 'Port ready',
    no_port: 'No proxy available',
    port: 'Port',
    click_create: 'Click to create',
    aves_line_success: 'Effective',
    aves_line_pump: 'Extracted',
    aves_min_average: 'Minute Avg',
    aves_hour_average: 'Hourly Avg',
    port_table_deal: 'Port Protocol',
    port_table_name: 'Port Remark',
    port_table_line: 'Online/Offline',
    port_table_hash: 'Current Hashrate',
    port_table_ping: 'Average Latency',
    port_table_status: 'Status',
    port_table_batch: 'Batch Operations',
    port_table_no_create: 'Not Created',
    port_table_port: 'Port',
    port_table_create: 'Click here to create',
    port_table_select: 'Please select',
    port_table_no_port: 'No ports under the current coin, please create one first.',
    port_table_select_all: 'Select All',
    port_table_open_port: 'Open Selected Ports',
    port_table_stop_port: 'Stop Selected Ports',
    port_table_delete_port: 'Delete Selected Ports',
    port_table_scripe_1: 'Batch operations completed',
    port_table_scripe_2: 'successful',
    port_table_scripe_3: 'failed',
    port_table_scripe_4: 'Proxy Pool:',
    port_table_scripe_5: 'Fee Pool',
    port_table_scripe_6: 'Fee Wallet',
    port_table_scripe_7: 'Not configured fees, much appreciated.',
    port_table_scripe_8: 'Configured',
    port_table_scripe_9: 'Fee Wallets:',
    port_table_scripe_10: 'Total Ratio',
    port_table_scripe_11: 'Forced distribution successful shares:',
    port_table_scripe_12: 'Enabled',
    port_table_scripe_13: 'Disabled',
    proxy_model_default_title: 'Basic Configuration',
    proxy_model_pump_title: 'Fee Configuration',
    proxy_model_replace_title: 'Replace Specific Wallet',
    proxy_model_auth_title: 'Other Configuration',
    proxy_model_copy_port: 'Copy Port',
    proxy_model_add_proxy: 'Add New Proxy',
    proxy_model_edit_proxy: 'Edit Proxy',
    proxy_model_copy_proxy: 'Copy Proxy',
    proxy_model_create_error: 'Creation Failed!! The selected currency is not supported in the current version. Please update the program if you need to create.',
    coin_class_title: 'Hashrate / Devices',
    home_log_card: 'Operation Results',
    index_hash_stat_hash_stat: 'Hashrate Statistics',
    index_hash_stat_current_hash: 'Real-time Hashrate',
    index_hash_stat_device_count: 'Device Count',
    index_hash_stat_online_count: 'Active Devices',
    index_hash_stat_die_count: 'Offline Devices',
    index_hash_stat_ping: 'Comprehensive Latency',
    index_hash_stat_current_ping: 'Current Average Latency',
    index_hash_stat_legend_ping: 'Latency',
    index_hash_stat_legend_online: 'Active Devices',
    index_hash_stat_legend_die: 'Offline Devices',
    port_list_card_menu: 'Operation Menu',
    port_list_card_review: 'View Miners in Port',
    port_list_card_edit: 'Edit Port',
    port_list_card_update: 'Hot Update Pump Configuration (Miners Will Not Drop)',
    port_list_card_copy: 'Copy Port',
    port_list_card_stop: 'Stop Port',
    port_list_card_open: 'Open Port',
    port_list_card_delete: 'Delete Port',
    port_list_card_alert_close: 'Close Port',
    port_list_card_alert_close_msg: 'Are you sure you want to close the port? Devices under this port will be disconnected.',
    line_chart_1_online: 'Active Devices',
    line_chart_1_die: 'Offline Devices',
    line_chart_4_total: 'Total',
    line_chart_6_ping: 'Latency',
    coin_card_create: 'Create New Proxy',
    coin_card_hide_no_proxy: 'Hide Currencies with No Proxy',
    coin_card_hide_no_active: 'Hide Inactive Currencies',
    coin_card_no_search: 'No matching items found, please create a proxy first',
    port_card_flow: 'Traffic In/Out',
    port_card_hard: 'Memory / CPU',
    port_card_rt: 'Real-time:',
    port_card_version: 'Program Version:',
    port_card_runtime: 'Program Runtime:',
    port_card_system: 'Operating System:',
    port_card_la: 'System Load (LA):',
    port_card_cpu: 'CPU Cores:',
    port_card_desk: 'Total/Used Disk:',
    port_card_mem: 'Total/Used Memory:',
    port_card_device: 'Total Devices',
    port_card_line: 'Online/Offline:',
    port_card_script_1: 'New version discovered {originVersion}, current version {localVersion}, click to view update log.',
    port_card_chart_online: 'Active Devices',
    port_card_chart_offline: 'Offline Devices',
    port_card_chart_mem: 'Memory',
    port_card_chart_ob: 'Received',
    port_card_chart_send: 'Sent',
    aves_line_select: 'Please Select',
    port_table_port_list: 'Port List',
    header_bar_com: 'Overall Statistics',
    header_bar_proxy: 'Pool Proxy',
    header_bar_all: 'View All Proxies',
    header_bar_create: 'Add New Proxy',
    header_bar_import: 'Import Proxy Configuration',
    header_bar_export: 'Export Proxy Configuration',
    header_bar_setting: 'Settings',
    header_bar_ip: 'All Port IP Access Records',
    header_bar_ban: 'Wallet Blacklist',
    header_bar_runlog: 'View Runtime Logs',
    header_bar_updatelog: 'View Update Logs',
    header_bar_language: 'Language',
    header_bar_theme: 'Theme',
    header_bar_default: 'Default Theme',
    header_bar_classic: 'Light Theme',
    header_bar_logout: 'Logout',
    menu_bar_re_proxy: 'View All {coin} Proxies',
    menu_bar_https: 'Set HTTPS Access for WEB Backend',
    menu_bar_kenc: 'Set KENC Custom Key',
    menu_bar_pool: 'Add Quick Pool',
    menu_bar_wallet: 'Add Quick Wallet Address',
    add_control_add_client: 'Add Client',
    add_control_edit_client: 'Edit Client',
    add_control_url_title: 'Access Address',
    add_control_auth_addr: 'Enter Other Client Backend Address',
    add_control_desc: 'Please fill in the corresponding client\'s backend access address, for example {origin}, if the corresponding backend is https, you need to fill in the https address, please make sure the backend address is accessible on the public network',
    add_control_other_api: 'Enter Other Client Group Control API',
    add_control_remark: 'Remark',
    add_control_at_remark: 'Enter any remark...',
    add_control_script_1: 'All three configurations are mandatory!',
    add_control_script_2: 'Added successfully',
    add_control_script_3: 'Failed to add',
    add_perset_pool_title: 'Add Quick Pool',
    add_perset_pool_desc: '- Add quick pool configuration here, can be quickly selected where needed.',
    add_perset_pool_select_coin: 'Please select corresponding currency',
    add_perset_pool_input_addr_label: 'Enter Pool Remark',
    add_perset_pool_input_addr_label_desc: 'Enter the remark of the pool for easy searching',
    add_perset_pool_input_addr: 'Enter Pool Address',
    add_perset_pool_input_addr_desc: 'Enter the pool address, please make sure there are no extra spaces or Chinese characters',
    add_perset_pool_input_deal: 'Select Pool Protocol',
    add_perset_pool_fast_button: 'Add Quick Pool',
    add_perset_pool_pool_preview: '- Quick Pool Preview',
    add_perset_pool_custom_pool: 'Custom Pool',
    add_perset_pool_scripe_1: 'No {selectCoin} custom pools yet, please add above',
    add_perset_pool_name: 'Remark',
    add_perset_pool_addr: 'Pool Address',
    add_perset_pool_deal: 'Protocol',
    add_perset_pool_opt: 'Operation',
    add_perset_pool_delete: 'Delete',
    add_perset_pool_in_pool: 'Pre-set Pool',
    add_perset_pool_in_scripe_1: 'No {selectCoin} pre-set pools yet, please contact the administrator to add, pre-set pools can be added via hot update.',
    add_perset_pool_script_1: 'Failed to get pre-set pool address.',
    add_perset_pool_script_2: 'Please fill in the information completely.',
    add_perset_pool_script_3: 'Failed to get data',
    add_perset_pool_script_4: 'Configuration successful!',
    add_perset_pool_script_5: 'Failed to get data',
    add_perset_pool_script_6: 'Pure forwarding',
    add_perset_wallet_title: 'Add Quick Wallet',
    add_perset_wallet_desc: '- Add quick wallet configuration here, can be quickly selected where needed.',
    add_perset_wallet_select_coin: 'Please select corresponding currency',
    add_perset_wallet_input_addr_label: 'Enter Wallet Remark',
    add_perset_wallet_input_addr_label_desc: 'Enter the remark of the wallet for easy searching',
    add_perset_wallet_input_addr: 'Enter Wallet Address',
    add_perset_wallet_input_addr_desc: 'Enter the wallet address, please make sure there are no extra spaces or Chinese characters',
    add_perset_wallet_input_deal: 'Select Wallet Protocol',
    add_perset_wallet_fast_button: 'Add Quick Wallet',
    add_perset_wallet_pool_preview: '- Quick Wallet Preview',
    add_perset_wallet_custom_pool: 'Custom Wallet',
    add_perset_wallet_scripe_1: 'No custom wallets yet, please add above',
    add_perset_wallet_name: 'Remark',
    add_perset_wallet_addr: 'Wallet Address',
    add_perset_wallet_deal: 'Protocol',
    add_perset_wallet_opt: 'Operation',
    add_perset_wallet_delete: 'Delete',
    add_perset_wallet_in_pool: 'Pre-set Wallet',
    add_perset_wallet_in_scripe_1: 'No pre-set wallets yet, please contact the administrator to add, pre-set wallets can be added via hot update.',
    add_perset_wallet_script_1: 'Failed to get pre-set wallet address.',
    add_perset_wallet_script_2: 'Please fill in the information completely.',
    add_perset_wallet_script_3: 'Failed to get data',
    add_perset_wallet_script_4: 'Configuration successful!',
    add_perset_wallet_script_5: 'Failed to get data',
    add_perset_wallet_script_6: 'Pure forwarding',
    device_model_hash_stat_title: 'Pool Hashrate',
    device_model_warning: '* Note: This device cannot cross-pool pump. The pumping has automatically switched to the proxy pool. Please pay attention to the pumping wallet in the proxy pool.',
    device_model_des: 'Description',
    device_model_name: 'Device Name:',
    device_model_wallet: 'Wallet Address:',
    device_model_acc: 'Valid Shares:',
    device_model_re: 'Invalid Shares / Active Abandonment:',
    device_model_hash: 'Current Hashrate:',
    device_model_15: '15-Minute Hashrate:',
    device_model_4: '4-Hour Hashrate:',
    device_model_24: '24-Hour Hashrate',
    device_model_c_p: 'Current Pumping Hashrate:',
    device_model_15_p: '15-Minute Pumping Hashrate:',
    device_model_4_p: '4-Hour Pumping Hashrate:',
    device_model_24_p: '24-Hour Pumping Hashrate:',
    device_model_local_hash: 'Local Hashrate:',
    device_model_device: 'Model / Core:',
    device_model_diff: 'Current Task Difficulty:',
    device_model_cut: 'Number of Device Disconnections:',
    device_model_ratio: 'Fee Ratio:',
    device_model_alg: 'Algorithm:',
    device_model_ping: 'Latency:',
    device_model_log_time: 'Device Login Time:',
    device_model_last_sub: 'Last Submission Time:',
    device_model_last_job: 'Last Task Issuance Time of Pool:',
    device_model_erupt: 'Concurrent:',
    device_model_log: 'Device Log',
    device_model_info: 'Regular users can ignore the following content. Usually, if a chip machine backend is set with three addresses, there may be multiple links working alternately here. Generally, there will be two links for backup addresses, and those two links will frequently disconnect and enter. To identify whether the miner is truly frequent disconnections, check the number of disconnections in the device list.',
    device_mode_loading: 'Loading, please wait...',
    device_mode_control: 'Console',
    device_mode_online: 'Online',
    device_mode_offline: 'Offline',
    device_mode_scripe_1: 'Connected',
    device_mode_scripe_2: 'Disconnected',
    device_mode_scripe_3: 'Connect',
    device_mode_scripe_4: 'Latency',
    engine_control_title: 'Algorithm Engine',
    engine_control_tag_1: 'View all current software algorithm engines, algorithm engines are used for the calculation and processing logic of corresponding currencies. Under the premise of supporting the current version, any currency supported by the current version kernel can be hot updated without feeling.',
    engine_control_tag_2: 'The engine will automatically hot update at regular intervals. During the hot update period, it will not cause any impact on the existing process, so you can rest assured. At the same time, you can also click the [Quick Update] button below to manually hot update.',
    engine_control_update: 'Quick Update',
    engine_control_select: 'Please Select',
    engine_control_all: 'All Algorithms',
    engine_control_script_1: 'Update successful',
    engine_control_script_2: 'Algorithm engine hot update successful, you can find the newly added currency when creating a proxy!',
    engine_control_script_3: 'Update failed',
    engine_control_script_4: 'Do not update frequently',
    err_log_title: 'Error Log',
    export_config_title: 'Export Proxy Configuration',
    export_config_script_1: 'Export {title} Port Configuration File',
    export_config_json: 'Export JSON Configuration',
    hot_wallet_title: 'Hot Wallet Configuration',
    hot_wallet_desc: 'Update configuration here without affecting port operation. If only the handling fee configuration is modified, this will be the best modification location.',
    hot_wallet_script_1: 'Assign Wallet - {wallet}',
    hot_wallet_fast: 'Quick Select',
    hot_wallet_update: 'Update Configuration',
    hot_wallet_cancel: 'Cancel',
    hot_wallet_script_2: 'Please select the proxy currency to create first.',
    hot_wallet_script_3: 'Hint',
    hot_wallet_script_4: 'If the hot wallet configuration here does not take effect for a long time, please click delete wallet on this interface, then recreate the wallet here, the device is not affected and will not disconnect.',
    hot_wallet_script_5: 'Assign Wallet - {wallet} Delete Successful',
    hot_wallet_script_6: 'Assign Wallet - {wallet} Deletion Failed',
    hot_wallet_script_7: 'Configuration Item Check Failed',
    hot_wallet_script_8: 'Assign Wallet - {wallet} Update Successful',
    hot_wallet_script_9: 'Assign Wallet - {wallet} Update Failed',
    hot_wallet_script_10: 'Assign Wallet - {wallet} Added Successfully',
    hot_wallet_script_11: 'Assign Wallet - {wallet} Addition Failed',
    hot_wallet_script_12: 'Assign Wallet - {wallet}, Wallet Address Check Failed.',
    hot_wallet_script_13: 'Assign Wallet - {wallet}, Device Name Check Failed.',
    hot_wallet_script_14: 'Assign Wallet - {wallet}, Pool Address Check Failed.',
    import_config_title: 'Import Proxy Configuration',
    import_config_json: 'Import JSON Configuration File',
    import_config_excel: 'Import Excel Port Configuration File',
    import_config_kt: 'Import KTMinerProxy Port Configuration File',
    import_config_script_1: 'Port',
    import_config_script_2: 'Error',
    import_config_script_3: 'Failed to parse excel file data - {error}, please check if the template format is correct',
    import_config_script_4: 'Failed to parse excel file format, currently only xlsx format files are supported',
    import_config_script_5: 'Invalid parameters, please check the template data format',
    import_config_script_6: 'Port already exists, please check if it is duplicated',
    import_config_script_7: 'Wallet parameter error, please check the wallet format of the template data',
    import_config_script_8: 'Import Successful',
    import_config_script_9: 'Failed to import file, please try again',
    import_config_script_10: 'Uncategorized',
    import_config_script_11: 'Port {port} import failed, category not found',
    import_config_script_12: 'Port already occupied',
    import_config_script_13: 'Port: {port} import failed. {error}',
    import_config_script_14: 'Port import successful',
    import_config_script_15: 'Port import failed',
    import_config_script_16: 'Port {port} import failed, rustminer does not currently support {coin}, ignore this port import.',
    ip_alert_p: 'Please select the operation to be performed on {ip}.',
    ip_alert_add: 'Add to Blacklist',
    ip_alert_relieve: 'Relieve Blacklist',
    ip_alert_area: 'View IP Location',
    ip_alert_script_1: 'Added to blacklist successfully! You can view or release it in Settings -> IP Blacklist.',
    ip_alert_script_2: 'Failed to add to blacklist!',
    ip_alert_script_3: 'Removed from blacklist successfully!',
    ip_alert_script_4: 'Failed to remove from blacklist!',
    ip_ban_title: 'IP Blacklist',
    ip_ban_loading: 'Loading...',
    ip_ban_p: 'Here shows the added IP blacklist. Click the IP address for related operations (you can also perform quick operations by clicking the corresponding IP in the IP access record or port log).',
    ip_ban_tips: 'Please enter the IP address to be banned from accessing the proxy port',
    ip_ban_add: 'Add IP',
    ip_ban_click: 'Click IP for Operation',
    ip_ban_script_1: 'Added to blacklist successfully!',
    ip_ban_script_2: 'Failed to add to blacklist!',
    ip_ban_script_3: 'Failed to get IP list.',
    ip_log_title: 'IP Access Record',
    ip_log_loading: 'Loading...',
    ip_log_p: 'Displays the most recent 100 IP access records. Click the IP address for related operations.',
    ip_log_time: 'Date:',
    ip_log_script_1: 'Failed to get IP list.',
    offline_push_title: 'Offline Reminder',
    offline_push_p: 'Offline Configuration',
    offline_push_tips: 'Reminder when miners go offline:',
    offline_push_p_1: 'For single device offline reminders, for example, after miner A goes offline, a notification that miner A is offline will be sent.',
    offline_push_label_1: 'Reminder on Port Hashrate Fluctuation: (Fluctuation Percentage)',
    offline_push_select: 'Please Select',
    offline_push_close: 'Close Fluctuation Reminder',
    offline_push_down: 'Reminder when Hashrate Decreases by {ratio}%',
    offline_push_tips_1: 'Reminder on port hashrate fluctuation changes. If the current hashrate is lower than the percentage of the previous 15 minutes, a notification will be sent.',
    offline_push_server: 'ServerChan Push Service',
    offline_push_server_p: 'Bind ServerChan push KEY to quickly and conveniently push offline information to WeChat. Click the logo on the left to view the service description.',
    offline_push_label_2: 'Reminder when Miners Go Offline:',
    offline_push_label_3: 'Enter SendKey',
    offline_push_save: 'Save Settings',
    offline_push_script_1: 'Failed to get configuration',
    offline_push_script_2: 'Configuration saved successfully',
    offline_push_script_3: 'Failed to save configuration',
    restart_model_title: 'Restart Service',
    restart_model_p_1: 'For Linux restart, please run the installation script and select the restart option.',
    restart_model_p_2: 'For the Windows version, please close the program directly and then reopen it.',
    rms_loading: 'First use of RMS, registering...',
    rms_tips_1: 'The RMS protocol can effectively compress and encrypt data during the process of transferring miners to {title}, compress data volume, reduce the number of public network connections, reduce local bandwidth occupation, maximize data transmission speed, reduce latency, and avoid man-in-the-middle attacks.',
    rms_down: 'Download Link:',
    rms_course: 'Usage Tutorial:',
    rms_push: 'Configure Push Address',
    rms_tips_2: '● Paste this ciphertext into the RMS local client to communicate with this server and obtain related configurations. (If you modify the web access port, domain name, or IP, the local client needs to be filled in again.)',
    rms_config_title: 'Quick Configuration',
    rms_pass_label: 'Password',
    rms_pass_place: 'Optional',
    rms_p_1: '● The password will be one of the encryption parameters of the client to ensure higher data security.',
    rms_port_label: 'Port Mapping',
    rms_port_default: 'Default',
    rms_port_custom: 'Custom',
    rms_none: 'No RMS protocol ports currently, please set RMS protocol ports first',
    rms_label_1: 'Server RMS Listening Port',
    rms_label_2: 'Local RMS Client Listening Port',
    rms_tips_3: '● In default mode, the RMS client will open all server ports for listening. For example, if ports 3333, 3334, and 3335 RMS are opened on the current server, the RMS client will also open these three ports locally to map to these three ports on the server, making it easy to use.',
    rms_tips_4: '● In custom mode, you can modify the listening ports of the local RMS client.',
    rms_label_3: 'Client Classification',
    rms_label_3_p: "Port remarks are set in the format of Category-Title. The client can automatically classify addresses. For example, if the port remark is set to BTC-Pool3306, the BTC option will be displayed in the client's classification list. After selecting, the list will display the connection address with the title Pool3306. (Note that the hyphen needs to be entered in English input method, not Chinese hyphen.)",
    rms_other: 'Other Configurations',
    rms_label_4: 'Client Name',
    rms_label_4_place: 'Software name displayed in the client',
    rms_label_5: 'Announcement',
    rms_label_5_place: 'Announcement information displayed in the client',
    rms_label_6: 'Other Information',
    rms_label_6_place: 'Other information displayed in the client',
    rms_update: 'Update Configuration',
    rms_script_1: 'Update successful!',
    rms_script_2: 'Update failed!',
    rms_script_3: 'Failed to register service.',
    run_log_title_1: 'Runtime Log',
    select_preset_pool_title: 'Select Preset Pool Address',
    select_preset_pool_label_1: 'Preset Pool',
    select_preset_pool_label_2: 'Remark',
    select_preset_pool_label_3: 'Pool Address',
    select_preset_pool_label_4: 'Protocol',
    select_preset_pool_label_5: 'Operation',
    select_preset_pool_label_6: 'Custom Pool',
    select_preset_pool_label_7: 'Remark',
    select_preset_pool_label_8: 'Pool Address',
    select_preset_pool_label_9: 'Protocol',
    select_preset_pool_label_10: 'Operation',
    select_preset_pool_p_1: '- Conveniently select pool addresses here. You can also add custom addresses in Settings -> Add Preset Pool Addresses.',
    select_preset_pool_empty: 'No {selectCoin} preset pools, please contact the administrator to add. Preset pools can be hot updated.',
    select_preset_pool_empty_1: 'No custom pools for {selectCoin}.',
    select_preset_pool_select: 'Select',
    select_preset_pool_script_1: 'Failed to get preset pool addresses.',
    select_preset_pool_script_2: 'Failed to get data.',
    select_preset_pool_script_3: 'Pure Forwarding',
    d_l_label_1: 'Wallet List',
    d_l_label_2: 'Favorites',
    d_l_label_3: 'Wallet Address',
    d_l_label_4: 'Active Devices',
    d_l_label_5: 'Offline Devices',
    d_l_label_6: 'Real-time Hashrate',
    d_l_label_7: 'Pumping Ratio',
    d_l_label_8: 'Action',
    d_l_label_9: 'Device Management',
    d_l_label_10: 'Device Name',
    d_l_label_11: 'Wallet Address',
    d_l_label_12: 'Online Duration',
    d_l_label_13: 'Efficiency',
    d_l_label_14: 'Pumping Ratio',
    d_l_label_15: 'Device Reconnect Times',
    d_l_label_16: 'Latency',
    d_l_label_17: 'Action',
    d_l_label_18: 'Port Log',
    d_l_label_19: 'Port Protocol',
    d_l_label_20: 'Current Active Devices',
    d_l_label_21: 'Current Offline Devices',
    d_l_label_22: 'Concurrent Connections',
    d_l_label_23: 'Port Number',
    d_l_label_24: 'Coin Type',
    d_l_label_25: 'Algorithm',
    d_l_label_26: 'Max Connections',
    d_l_label_27: 'Device Connection Address',
    d_l_label_28: 'Proxy Address',
    d_l_label_29: 'Backup Connection Address',
    d_l_label_30: 'Pumping Ratio',
    d_l_label_31: 'Pumping Wallet',
    d_l_label_32: 'Console',
    d_l_content_1: 'After entering this page, the wallet list is displayed by default. Close this status to display the miner list by default.',
    d_l_content_2: 'Shows the client pool hashrate and pumping pool hashrate within 15 minutes.',
    d_l_content_3: 'The effective ratio of device operation',
    d_l_content_4: 'This device has been individually set ratio',
    d_l_content_5: 'Number of times the device has reconnected. If the number is too high, please check whether there are problems with the upstream network environment, miner status, and other links.',
    d_l_content_6: 'View device details',
    d_l_content_7: 'Individually adjust the pumping ratio of this miner',
    d_l_place_1: 'Filter by wallet, can select multiple',
    d_l_p_1: 'All wallets',
    d_l_p_2: 'Favorite wallets',
    d_l_p_3: 'View all devices of the wallet',
    d_l_p_4: 'Set the handling fee for this wallet',
    d_l_p_5: 'Favorite wallet',
    d_l_p_6: 'Unfavorite wallet',
    d_l_p_7: 'All devices',
    d_l_p_8: 'Online devices',
    d_l_p_9: 'Offline devices',
    d_l_p_10: 'All wallets',
    d_l_p_11: 'Wallet:',
    d_l_p_12: 'All devices',
    d_l_p_13: 'Online devices',
    d_l_p_14: 'Offline devices',
    d_l_p_15: 'A total of {var_1} devices were retrieved, {var_2} online, {var_3} offline, real-time hashrate {var_4}',
    d_l_p_16: 'A total of {var_1} online devices were retrieved, real-time hashrate {var_2}',
    d_l_p_17: 'A total of {var_1} offline devices were retrieved, real-time hashrate {var_2}',
    d_l_p_18: 'Machine name not configured',
    d_l_p_19: 'Hashrate',
    d_l_p_20: 'Efficiency',
    d_l_p_21: 'Pumping Ratio',
    d_l_p_22: 'Reconnect Times',
    d_l_p_23: 'Latency',
    d_l_p_24: 'Pumping Device Name:',
    d_l_p_25: 'The port has not configured a pumping wallet, so the pumping ratio of this device cannot be set individually',
    d_l_p_26: 'Device connection log',
    d_l_p_27: 'No connection logs',
    d_l_p_28: 'Wallet Address',
    d_l_p_29: 'Device Name',
    d_l_p_30: 'Port Log',
    d_l_p_script_1: 'Individually set ratio',
    d_l_p_script_2: 'Wallet redirected',
    d_l_p_script_3: 'Efficient',
    d_l_p_script_4: 'Compatible',
    d_l_p_script_5: 'Mining Pool',
    d_l_p_script_6: 'Efficient Mode (auto-optimization enabled)',
    d_l_p_script_7: 'Compatible Mode',
    d_l_p_script_8: 'Mining Pool Mode',
    d_l_p_script_9: 'Latency',
    d_l_p_script_10: 'Unfavorite',
    d_l_p_script_11: 'Favorited successfully',
    d_l_p_script_12: 'Failed to favorite!',
    d_l_p_script_13: 'Failed to get port LOG.',
    d_l_p_script_14: 'Connected.',
    d_l_p_script_15: 'Disconnected.',
    d_l_p_script_16: 'Device',
    d_l_p_script_17: 'Blacklisted IP access, rejected.',
    d_l_p_script_18: 'Blacklisted wallet {ip} access, rejected',
    d_l_p_script_19: 'Action',
    d_l_p_script_20: 'Query failed',
    d_l_p_script_21: 'No device data found, please refresh and try again.',
    d_l_p_script_22: 'Failed to get port wallets.',
    d_l_p_script_23: 'Failed to retrieve data',
    d_l_p_script_24: 'Failed to retrieve list',
    select_perset_wallet_title: 'Select Quick Wallet Address',
    select_perset_wallet_label_1: 'Custom Wallet',
    select_perset_wallet_label_2: 'Remark',
    select_perset_wallet_label_3: 'Wallet Address',
    select_perset_wallet_label_4: 'Action',
    select_perset_wallet_empty: 'No custom wallets yet',
    select_perset_wallet_p_1: '- Conveniently select wallet addresses here. You can also add custom addresses in Settings -> Add Quick Wallet Addresses.',
    select_perset_wallet_p_2: '- Wallet Preview',
    select_perset_wallet_p_3: 'Select',
    select_perset_wallet_error: 'Failed to retrieve data',
    set_https_title: 'Set HTTPS Backend Access',
    set_https_label_1: 'Enable HTTPS Access',
    set_https_p_1: 'Set whether the web backend is accessed via HTTPS. After setting, the program needs to be restarted to take effect. On Linux, you can restart with the installation script. On Windows, simply close and reopen the program. (Note: Do not open the backend with different protocols, otherwise, the backend will not open. If it is HTTP, use HTTP to open it, and if it is HTTPS, use HTTPS to open it.)',
    set_https_sciprt_1: 'Failed to retrieve HTTPS status.',
    set_https_sciprt_2: 'Please note',
    set_https_sciprt_3: `
        Setting https successful, <span style="color:red;">requires restart</span> of the software to take effect. After restarting the software, please manually enter <span style="color:red;">https://</span> prefix to access, otherwise some browsers may request cached content resulting in inability to access the backend. <br><br>
        If you are using the <span style="color:red;">RMS</span> client, please open Settings-RMS after restarting, click Update Configuration to get the latest RMS push address, the old address will become invalid.
    `,
        set_https_sciprt_4: `
        Setting http successful, <span style="color:red;">requires restart</span> of the software to take effect. After restarting the software, please manually enter <span style="color:red;">https://</span> prefix to access, otherwise some browsers may request cached content resulting in inability to access the backend. <br><br>
        If you are using the <span style="color:red;">RMS</span> client, please open Settings-RMS after restarting, click Update Configuration to get the latest RMS push address, the old address will become invalid.
    `,
    set_https_sciprt_5: "Failed to set https status.",
    set_kenc_key_title: "KENC Custom Key",
    set_kenc_p_1: "This is an advanced feature suitable for developing local client encryption, encryption type is RSA128. If you do not understand the purpose of this feature, do not set it, otherwise it will cause the KENC port to be unable to connect.",
    set_kenc_p_2: "Save Changes",
    set_kenc_p_3: "Restore Defaults (Do Not Use Custom)",
    set_kenc_label_1: "Password",
    set_kenc_label_2: "Key",
    set_kenc_place_1: "Generate key based on this password.",
    set_kenc_place_2: "Automatically generated after configuring password.",
    set_kenc_script_1: "Restore successful.",
    set_kenc_script_2: "Setting successful.",
    set_port_model_title: "Set WEB Access Port",
    set_port_model_p_1: "After modifying the WEB access port, the software will restart. Please use the new port number for access.",
    set_port_model_p_2: "WEB Access Port",
    set_port_model_p_3: "Save Changes",
    set_port_model_label_1: "Port Number",
    set_pump_by_w_title: "Set Pump Ratio for All Devices Under Wallet",
    set_pump_by_w_p_1: "Specifically for the wallet",
    set_pump_by_w_p_2: "Adjust the pumping ratio of all devices under the wallet separately, does not affect other devices. If both the individual miner ratio and the wallet ratio are set at the same time, the priority is as follows: individual miner set ratio > wallet ratio > port ratio.",
    set_pump_by_w_p_3: "Save Configuration",
    set_pump_by_w_p_4: "Restore Configuration",
    set_pump_by_w_p_5: "Cancel",
    set_pump_by_w_script_6: "Update successful, all miners under wallet {targetWallet} will be pumped to wallet {data.addr} proportionally!",
    set_pump_by_w_script_7: "Setting failed!",
    set_pump_by_w_script_8: "Restored.",
    set_pump_by_w_script_9: "Restoration failed.",
    set_user_title: "Modify Username, Password",
    set_user_p_1: "After installing the software, please change the default username and password as soon as possible to prevent scanning intrusion. Don't wait until it's too late to <span style=\"color: red;\">regret</span>. (The username can also be changed)",
    set_user_p_2: "Current Account Information",
    set_user_p_3: "Modify Information",
    set_user_p_4: "Save Changes",
    set_user_place_1: "Current Username",
    set_user_place_2: "Current Password",
    set_user_place_3: "New Username",
    set_user_place_4: "Confirm New Username",
    set_user_place_5: "New Password",
    set_user_place_6: "Confirm New Password",
    set_user_script_1: "Modification failed",
    set_user_script_2: "Configuration check failed",
    set_user_script_3: "Modification successful!",
    set_worker_ratio_title: "Set Individual Ratio",
    set_worker_ratio_p_1: "Adjust the pumping ratio for this device separately, does not affect other devices.",
    set_worker_ratio_p_2: "Save Configuration",
    set_worker_ratio_p_3: "Restore Configuration",
    set_worker_ratio_p_4: "Cancel",
    set_worker_ratio_script_1: "Prompt",
    set_worker_ratio_script_2: "Individually configure the miner pumping ratio. Wallets on the port must have a ratio set to take effect. If you do not want a pumping ratio on the port, you can set the port wallet ratio to 0.01.",
    set_worker_ratio_script_3: "Failed to retrieve ratio.",
    set_worker_ratio_script_4: "Independent ratio set successfully!",
    set_worker_ratio_script_5: "Failed to set independent ratio!",
    set_worker_ratio_script_6: "Cleared successfully!",
    set_worker_ratio_script_7: "Clearing failed!",
    set_upload_cert_title: "Global TLS/SSL Certificate",
    set_upload_cert_p_1: "Currently in use",
    set_upload_cert_p_2: "Certificate.",
    set_upload_cert_p_3: "Select crt or pem certificate file",
    set_upload_cert_p_4: "Select key certificate file",
    set_upload_cert_p_5: "crt, pem Preview",
    set_upload_cert_p_6: "key Preview",
    set_upload_cert_p_7: "Save and Enable Custom Certificate",
    set_upload_cert_p_8: "Restore Built-in Certificate",
    set_upload_cert_script_1: "Using built-in certificate",
    set_upload_cert_script_2: "Using custom certificate",
    set_upload_cert_script_3: "Built-in",
    set_upload_cert_script_4: "Custom",
    set_upload_cert_script_5: "Error",
    set_upload_cert_script_6: "Failed to configure certificate correctly",
    set_upload_cert_script_7: "Save successful!",
    set_upload_cert_script_8: "Restore successful!",
    uuid_model_title: "View Local UUID",
    version_log_title: "Update Log",
    version_log_p_1: "Current Program Version:",
    version_log_p_2: "Latest Program Version:",
    version_log_p_3: "Fetching...",
    version_log_script_1: "Failed to fetch update log!",
    wallet_alert_p_1: "Please select an action to perform on {wallet}.",
    wallet_alert_p_2: "Add to Blacklist",
    wallet_alert_p_3: "Remove from Blacklist",
    wallet_alert_script_1: "Added to blacklist successfully! You can view or remove it in Settings -> Wallet Blacklist.",
    wallet_alert_script_2: "Failed to add to blacklist!",
    wallet_alert_script_3: "Removed from blacklist successfully!",
    wallet_alert_script_4: "Failed to remove from blacklist!",
    wallet_ban_title: "Wallet Blacklist",
    wallet_ban_p_1: "Reading...",
    wallet_ban_p_2: "Here shows the wallets that have been added to the wallet blacklist. Click on the wallet for related operations (you can also perform quick operations by clicking on the corresponding wallet in the port log).",
    wallet_ban_p_3: "Add Wallet",
    wallet_ban_p_4: "Wallet Address:",
    wallet_ban_p_5: "Click wallet address for operations",
    wallet_ban_place: "Please enter the wallet address to be blocked",
    wallet_ban_script_1: "Added to blacklist successfully!",
    wallet_ban_script_2: "Failed to add to blacklist!",
    wallet_ban_script_3: "Failed to get wallet list.",
    who_am_p_1: "Customize your exclusive version for free:",
    who_am_p_2: "You hereby confirm and declare:",
    who_am_p_3: "1. RustMinerSystem is regulated by Hong Kong law. Please note that the laws of different countries/regions may restrict such products and services.",
    who_am_p_4: "2. Therefore, this product and service, as well as certain features, may not be available, or may be restricted in certain jurisdictions or regions or among certain users.",
    who_am_p_5: "3. This product is not a VPN type product because it cannot allow access to prohibited content in regions where access is not allowed.",
    who_am_p_6: "4. This product is a miner and mining farm management software, not obtaining miner data by improper means, all connected devices require the device owner to actively set the miner connection address to ensure that any customer using this program has the right to be informed.",
    who_am_p_7: "5. You are not listed in any terrorist organization and terrorist personnel lists, as listed in the resolutions of the United Nations Security Council.",
    who_am_p_8: "6. You are not restricted or prohibited by any administrative law enforcement agencies in any country or region from using this program.",
    who_am_p_9: "7. You are not residents of countries or regions subject to sanctions by relevant national governments or international organizations, such as Cuba, Iran, North Korea, Syria, and others.",
    who_am_p_10: "8. You are not residents of countries or regions where activities related to digital currencies are restricted or prohibited, including but not limited to mainland China.",
    who_am_p_11: "9. The services provided by this program in your country or region comply with relevant laws, regulations, and policies.",
    who_am_p_12: "10. You agree that if your use of the services of this program violates the laws of your country or region due to the laws, regulations, and policies of your country or region or any other applicable laws, you will independently bear the relevant legal risks and responsibilities, and you unconditionally and irrevocably waive your right to claim against this program.",
    who_am_p_13: "11. You should understand and comply with local laws and regulations. By using this product, you default to accepting the above license. The legal issues arising from this product are unrelated to this product.",
    who_am_p_14: "12. Special Thanks:",
    top_panel_chart_title: 'Pool Hashrate',
    top_panel_p_1: 'Current Hashrate',
    top_panel_content_1: 'Edit Port',
    top_panel_content_2: 'Hot Update Fee',
    top_panel_content_3: 'Stop Port',
    top_panel_content_4: 'Start Port',
    top_panel_content_5: 'Delete Port',
    top_panel_script_1: 'Port status normal',
    top_panel_script_2: 'Port closed',
    top_panel_script_3: 'Queuing, please wait...',
    top_panel_script_4: 'Close Port',
    top_panel_script_5: 'Are you sure you want to close the port? Devices under this port will be disconnected.',
    top_panel_script_6: 'Notice',
    top_panel_script_7: 'The calculation unit of this currency has not been installed, which does not affect the pumping logic and actual operation.',
    top_panel_script_8: 'Try not to pump LTC to Fishpool. It is recommended to pump to viabtc for more accurate hashrate.',
    top_panel_script_9: 'Attention, it is recommended to mine Alph on herominer. There are diff issues with other pools. If you want to run other pools, it is recommended to use nginx for pure forwarding.',
    top_panel_script_10: 'Do not use bzminer to mine RXD!!!',
    top_panel_script_11: '[BTC] If there are devices in the port that do not support cross-pool pumping, the background color of the list corresponding to the device will turn yellow, and the system will automatically force the pumping of this device to the proxy pool address, usually only a few old models will occur.',
    top_panel_script_12: 'This currency has enabled client hashrate protection mechanism. In order to minimize the loss of client hashrate, only the client loss critical point can be pumped. Therefore, there may be a situation of less pumping. If you need to close it, please change this configuration in the port editing.',
    auth_config_label_1: 'Force Successful Share Submission',
    auth_config_content_1: 'If submission fails, force the miner to return success. In this way, the miner or kernel displays 100% success.',
    deal_config_p_1: 'Port Protocol',
    deal_config_p_2: 'Compatible with the local client protocol of old KT, KENC clients can directly access here.',
    deal_config_p_3: 'The port allows mixed protocols to enter. Devices can enter this port via TCP or SSL.',
    deal_config_p_4: 'TCP/TLS Dual Protocol',
    default_config_p_1: 'OKMiner Optimization:',
    default_config_p_2: 'Enable',
    default_config_p_3: 'Disable',
    default_config_p_4: 'mallob Address:',
    default_config_p_5: 'Hashrate Protection:',
    default_config_p_6: 'Quick Select',
    default_config_p_7: 'Waiting for Input',
    default_config_content_1: 'Cannot connect to OKMiner mining machines, you can optimize by enabling this option.',
    default_config_content_2: 'Maximize protection of client hashrate loss. After enabling, it may not pump enough, only pump as much hashrate as possible before client loss occurs, but client hashrate will not be lost. If disabled, the goal is precise pumping, but no protection measures are taken for client hashrate. Please consider the switch on your own.',
    default_config_place_1: 'Max 65535',
    default_config_script_1: 'Please select the proxy currency to create first.',
    default_config_script_2: 'Pure forwarding',
    default_config_script_3: 'Attention',
    default_config_script_4: `
        ** Attention to local mining kernel versions **
        <br><br>
        DNX has announced the closure of mallob verification. There is no need to configure mallob address anymore. Please use the latest mining kernel for mining.
        <br><br>
        Although the verification has been closed, it seems that the official has not closed the verification according to the announced time. Please wait for the subsequent notifications from the mining pool and the official. Before that, please do not turn on pumping. The program still retains the proxy forwarding of mallob. Your mallob address is:
        <br><br>
        {mobURL}
        <br><br>
        If you have not upgraded, please make sure to upgrade your miners to the latest version (OZM 1.2.5 or SRB 2.3.9). The official will stop supporting old versions from 04:00 on October 21 (it seems that it has not taken effect yet). Thank you for your support!
        <br><br>
        Nvidia: <a href="https://github.com/OneZeroMiner/onezerominer/releases" target="_blank">OneZeroMiner</a><br>
        AMD: <a href="https://github.com/doktor83/SRBMiner-Multi/releases" target="_blank">RBMiner</a><br> 
        Please do not use DynexSolve kernel for mining.
    `,
    default_config_script_5: 'Attention',
    default_config_script_6: 'Create a pure forwarding port. This port only takes effect on configurations such as [Port Number], [Max Connections], [Port Configuration], [Proxy Pool Address, Protocol], and other configurations are not effective, without data statistics and handling fee functions. This port can enter devices of any currency and model.',
    default_config_script_7: 'Try not to pump LTC to Fishpool. The best choice is to pump to viabtc or other pools. Cross-pool pumping does not affect clients. Fishpool will severely pump insufficiently due to pool reasons.',
    default_config_script_8: 'Attention, it is recommended to mine Alph on herominer. There are diff issues with other pools. If you want to run other pools, it is recommended to use nginx for pure forwarding.',
    default_config_script_9: 'Do not use bzminer to mine RXD!!!',
    pump_config_p_1: 'Quick Select',
    pump_config_script_1: 'Please select the proxy currency to create first.',
    pump_config_script_2: 'I understand that different proxy pool and fee pool addresses may cause devices to not work properly.',
    pump_config_script_3: 'Addition failed',
    pump_config_script_4: 'You can only add up to ten wallets at most',
    client_con_label_1: 'Client Version',
    client_con_label_2: 'Program Running Time',
    client_con_label_3: 'Remarks',
    client_con_label_4: 'Hashrate',
    client_con_label_5: 'Online / Offline',
    client_con_label_6: 'All',
    client_con_label_7: 'CPU / Memory',
    client_con_label_8: 'Upload / Download',
    client_con_label_9: 'Operation',
    client_con_empty_1: 'No clients added yet.',
    client_con_place_1: 'Please Select',
    client_con_p_1: 'Fill in the API of other {CONFIG.TITLE} here for convenient observation and management.',
    client_con_p_2: 'Local API:',
    client_con_p_3: 'Refresh API',
    client_con_p_4: '(After refreshing the local API, the original API will be invalidated, and the control end needs to re-enter the API of the controlled client.)',
    client_con_p_5: '+ Add Client',
    client_con_p_6: 'Version',
    client_con_p_7: 'Program Running Time',
    client_con_p_8: 'Remarks',
    client_con_p_9: 'Real-time Hashrate',
    client_con_p_10: 'Online / Offline',
    client_con_p_11: 'CPU / Memory',
    client_con_p_12: 'Upload / Download',
    client_con_p_13: 'Quick View',
    client_con_p_14: 'Remote Access',
    client_con_p_15: 'Edit',
    client_con_p_16: 'Delete',
    client_con_script_1: 'Fetching...',
    client_con_script_2: 'Failed to fetch',
    client_con_script_3: 'Failed to refresh token!!!',
    client_con_script_4: 'Delete this item',
    client_con_script_5: 'Are you sure you want to delete this?',
    client_con_script_6: 'Deleted successfully',
    client_con_script_7: 'Deletion failed',
    client_con_script_8: 'Failed to fetch API!!!',
    client_con_script_9: 'Not yet fetched, please refresh later.',
    client_con_script_10: 'Failed to fetch the list.',
    client_con_script_11: 'Active Devices',
    client_con_script_12: 'Offline Devices',
    client_con_script_13: 'Memory',
    client_con_script_14: 'CPU',
    client_con_script_15: 'Receive',
    client_con_script_16: 'Send',
    login_view_script_1: 'Forgot your password?',
    login_view_script_2: `
        If you forgot your password, on Linux, you can run the installation script and choose the option to reset the password to restore the default account password.
        <br><br>
        If you are on Windows, please manually delete all files starting with 0. in the program directory after ending the program, and then restart the program to restore the default account password.
        <br><br>
        Default account password:
        <br><br>
        qzpm19kkx xloqslz913
    `,
    login_view_script_3: 'Information check failed!',
    login_view_script_4: 'Login successful!',
    port_inside_script_1: 'Please wait...',
    port_inside_script_2: 'Open Port',
    port_inside_script_3: 'Close Port',
    port_inside_script_4: 'Open Port',
    port_inside_script_5: 'Port closed successfully',
    port_inside_script_6: 'Failed to close port',
    port_inside_script_7: 'Port opened successfully',
    port_inside_script_8: 'Failed to open port',
    port_inside_script_9: 'Delete Port',
    port_inside_script_10: 'Are you sure you want to delete this port?',
    port_inside_script_11: 'Port deleted successfully',
    port_inside_script_12: 'Failed to delete port',
    system_view_script_1: 'Detected default account password being used. Please be sure to change the account password first.',
    line_chart_3_script_1: 'Hourly average：',
    set_safe_route: "Set Secure Access Address",
    set_safe_p_1: "Set an access address that only you know to avoid being scanned by the backend, thereby enhancing security and preventing brute force intrusion. It is essential to set this.",
    set_safe_p_2: "Example:",
    set_safe_p_3: "If your backend access address is http://1.2.3.4:3333 and the secure address is set to 'nihao', after saving, the backend can only be accessed through http://1.2.3.4:3333/nihao/",
    set_safe_p_4: "After setting up, the program will automatically restart. Linux users who do not use scripts for installation, please pay attention to starting the program. Windows users, after setting up, please manually start the program.",
    set_safe_p_5: "Be sure to remember the secure address you set. If forgotten, Linux users should run the installation script to view the route, and Windows users should find the 'rust-config' file in the directory, where the ROUTE field is the custom address.",
    set_safe_p_6: "Custom secure addresses are not allowed to contain any special characters, only English strings. Please do not include special symbols like (/ // \\ ?).",
    set_safe_p_7: "Save Secure Address",
    set_safe_p_8: 'Attention!!! When accessing after setting up, the URL to be accessed must end with/, for example http://a.b.com:1234/fuck/ Otherwise, you will not be able to access the backend.',
    set_safe_label_1: "Backend Access Address Preview:",
    set_safe_label_2: "Custom Secure Address:",
    set_safe_label_3: "Secure address not set yet, please input here",
    set_safe_route_script_1: "Warning",
    set_safe_route_script_2: "You have not set a secure backend address yet. Please go to Settings -> Set Secure Access Address menu to set it up.",
    set_safe_route_script_3: "Failed to retrieve route.",
    set_safe_route_script_4: "Not allowed to carry //",
    set_safe_route_script_5: "Not allowed to end with /",
    set_safe_route_script_6: "Not allowed to start with /",
    set_safe_route_script_7: "Not allowed to carry ?",
    set_safe_route_script_8: "Confirm Configuration",
    set_safe_route_script_9: `
    <p>The backend access address is about to change to：</p>
    <br>
    <p>
        <a href="{preview_url}" target="_blank">{preview_url}</a>    
    </p>
    <br>
    <p>Please remember this.</p>
    <br>
    <p>After confirmation, the program will restart. Linux users who do not use scripts for installation, please pay attention to starting the program. Windows users, after setting up, please manually start the program.</p>
    `,
    set_safe_route_script_10: 'Setup Complete',
    set_safe_route_script_11: 'Please wait for automatic refresh. Windows users, please reopen the program. After the program starts, manually access the new address.',
    auth_config_label_2: 'Optimization of old models',
    auth_config_content_2: "For mining machines that cannot support pumping, these machines will go through the customer's mining pool for pumping. If this function is not needed, please turn it off."
}